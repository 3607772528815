const K_COUNTRIES = [
    { id: 1, label: "United States", value: "United States" },
    { id: 2, label: "Canada", value: "Canada" },
    // { id: 2, label: "Canada", value: "Canada" },
    // { id: 3, label: "United Kingdom", value: "United Kingdom" },
    // { id: 4, label: "Australia", value: "Australia" },
    // { id: 5, label: "Germany", value: "Germany" },
    // { id: 6, label: "France", value: "France" },
    // { id: 7, label: "Japan", value: "Japan" },
    // { id: 8, label: "China", value: "China" },
    // { id: 9, label: "India", value: "India" },
    // { id: 10, label: "Brazil", value: "Brazil" },
    // { id: 11, label: "South Africa", value: "South Africa" },
    // { id: 12, label: "Italy", value: "Italy" },
    // { id: 13, label: "Spain", value: "Spain" },
    // { id: 14, label: "Mexico", value: "Mexico" },
    // { id: 15, label: "Russia", value: "Russia" },
    // { id: 16, label: "South Korea", value: "South Korea" },
    // { id: 17, label: "Netherlands", value: "Netherlands" },
    // { id: 18, label: "Sweden", value: "Sweden" },
    // { id: 19, label: "Norway", value: "Norway" },
    // { id: 20, label: "Denmark", value: "Denmark" }
  ];

  export {K_COUNTRIES}