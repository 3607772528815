import React from "react";
import ReactPaginate from 'react-paginate';
import commonStyle from "../style/commonStyle.module.css";
import {MdKeyboardArrowLeft, 
    MdKeyboardArrowRight} from "react-icons/md";

export default function Pagination({
  totalPageNum,
  onPageChange,
  forcePage
}) {
  return (
    <div>
        <ReactPaginate
        breakLabel="..."
        previousLabel={<div><MdKeyboardArrowLeft /></div>}
        nextLabel={<div><MdKeyboardArrowRight /></div>}
        onPageChange={onPageChange}
        pageRangeDisplayed={5}
        pageCount={totalPageNum}
        forcePage={forcePage}
        renderOnZeroPageCount={null}
        containerClassName={commonStyle.paginationCon}
        pageLinkClassName={commonStyle.pageItem}
        breakLinkClassName={commonStyle.pageItem}
        activeLinkClassName={commonStyle.pageItemActive}
        previousLinkClassName={commonStyle.pageItem}
        nextLinkClassName={commonStyle.pageItem}
        
      />
       
    </div>
  );
}
