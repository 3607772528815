import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import commonStyle from "../../../style/commonStyle.module.css";
import useDocumentTitle from "../../../useDocumentTitle";
import ScreenTitle from "../../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import { ErrorMessage, Loader } from "../../../components/smallComponents";
import { useDispatch, useSelector } from "react-redux";
import Pagination from "../../../components/Pagination";
import { EditLinkButton, LinkButton } from "../../../components/buttons";
import { adsList, questionList } from "../../../api/webContentRequets";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function AdsList() {
  useDocumentTitle("Ads");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [ads, setAds] = useState();

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    const getQuestionList = async () => {
      try {
        setReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await adsList(pageNum, pageSize, config, dispatch);
        if (responseData.data.success) {
          setReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          setAds(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };

    getQuestionList();
  }, [dispatch, pageNum]);

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/web-content/ads">
            Ads
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Ads" />

        {reqStatus.loading ? (
          <Loader mess="Requesting ads..." />
        ) : reqStatus.success ? (
          <div className={commonStyle.longDataTable}>
            <div className="w-full flex justify-end">
              <LinkButton
                path="/web-content/ads/new"
                name="New Ad"
                width="fit"
              />
            </div>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Position</th>
                  <th>Image</th>
                  <th>Url</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {ads.ads.length > 0 ? (
                  ads.ads.map((item, index) => (
                    <tr key={index}>
                      <td>{item.type}</td>
                      <td className="w-1/2">
                        <div className="w-full h-[240px]">
                          {item.image ? (
                            <img
                              alt={item.image}
                              src={S3_DOMAIN + item.image}
                              className="w-full h-full object-cover"
                              style={{
                                maxWidth: "100%",
                                maxHeight: "100%",
                              }}
                            />
                          ) : (
                            <img
                              alt={"default-watch"}
                              src="/default-car.svg"
                              className="w-1/3 h-full object-cover"
                              style={{
                                maxWidth: "30%",
                                maxHeight: "30%",
                              }}
                            />
                          )}
                        </div>
                      </td>
                      <td>{item.uri}</td>
                      <td>
                        <EditLinkButton
                          path={`/web-content/ads/edit/${item.id}`}
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>
                      There are no ad available. Please add a new one now.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            {ads.ads.length > 0 ? (
              <Pagination
                totalPageNum={ads.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : reqStatus.error ? (
          <ErrorMessage mess={reqStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
