
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const verifyUserRequest = async (userid, config) => {

  try {
    const response = await axios.get(SERVER_URL + `/admin/user/details/verify/${userid}`, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const inviteUserRequest = async (userid, config) => {

  try {
    const response = await axios.get(SERVER_URL + `/admin/user/invite-activate/${userid}`, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const basicDialogInfoRequest = async (dialogId, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/message/basic-dialog/${dialogId}`, config);
    return response;
  } catch (error) {
    throw error;
  }
};

export const removeProductRequest = async (productId, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/product/remove-product/${productId}`, config);
    return response;
  } catch (error) {
    throw error;
  }
};


