
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { newBlogRequest, updateBlogRequest } from "../../api/blogRequests";
import SingleImageUploader from "../../components/SingleImageUploader";
import { SimpleDialog } from "../../components/dialogs";
import { FieldLabel, TextAreaField, TextField } from "../../components/inputs";
import commonStyle from "../../style/commonStyle.module.css";
import CustomJoditEditor from "./CustomJoditEditor";
import { Button } from "../../components/buttons";



export default function BlogForm({ initialContent, blogId, type }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  //  dialog
  const [dialog, setDialog] = useState(false);

  const [formState, setFormState] = useState(initialContent);

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["blogImage"]: file.uri });
    }
  };

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      if(type==="new"){
        saveContent();
      }else{
        updateContent();
      }
      
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = "Blog title is required.";
    }

    if (!values.metaTitle) {
      errors.metaTitle = "Blog meta title is required.";
    }
    if (!values.metaDescription) {
      errors.metaDescription = "Blog meta description is required.";
    }
    if (!values.blogImage) {
      errors.blogImage = "Blog image is required.";
    }

    return errors;
  };

  const saveContent = async () => {
    try {
      setSaveStatus({ ...saveStatus, loading: true });

      const responseData = await newBlogRequest(formState, config);

      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
        setDialog(true);
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };

  const updateContent = async () => {
    try {
      setSaveStatus({ ...saveStatus, loading: true });

      const responseData = await updateBlogRequest(blogId, formState, config);

      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
        setDialog(true);
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/blogs", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title={`${type === "new" ? "New" : "Update"} Blog`}
        successMessage={`Blog ${type === "new" ? "added" : "updated"} successfully.`}
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="flex">
        <div className="w-1/2">
          <TextField
            type="text"
            label="Blog Title*"
            placeholder={"The blog title"}
            name="title"
            autofocus={true}
            value={formState.title}
            onChange={(title) =>
              handleChange({
                target: { value: title, name: "title" },
              })
            }
            error={formErrors.title}
          />

          <TextField
            type="text"
            label="Blog Meta title*"
            name="slug"
            autofocus={true}
            placeholder={"The title appear in the header (for SEO)"}
            value={formState.metaTitle}
            onChange={(metaTitle) =>
              handleChange({
                target: { value: metaTitle, name: "metaTitle" },
              })
            }
            error={formErrors.metaTitle}
          />
          <TextAreaField
            type="text"
            label="Blog Meta Description*"
            placeholder={"The description appear in the meta tag (for SEO)"}
            name="slug"
            rows={2}
            autofocus={true}
            value={formState.metaDescription}
            onChange={(metaDescription) =>
              handleChange({
                target: { value: metaDescription, name: "metaDescription" },
              })
            }
            error={formErrors.metaDescription}
          />

          <div className="flex ">
            <div className="w-1/2">
              <TextField
                type="text"
                label="Author"
                name="author"
                autofocus={true}
                placeholder={"The blog author"}
                value={formState.author}
                onChange={(author) =>
                  handleChange({
                    target: { value: author, name: "author" },
                  })
                }
              />
            </div>
            <div className="w-1/2 ml-4">
              <TextField
                type="text"
                label="Read Time"
                name="readTime"
                placeholder={"The read time (in minutes)"}
                autofocus={true}
                value={formState.readTime}
                onChange={(readTime) =>
                  handleChange({
                    target: { value: readTime, name: "readTime" },
                  })
                }
                unit="min"
              />
            </div>
          </div>
        </div>
        <div className="w-1/2 ml-8 p-4">
          <FieldLabel label="Blog Image" />
          <SingleImageUploader
            onUpload={getUploadedImages}
            imageError={formErrors.blogImage}
            uri={formState.blogImage}
            folderName="blog"
            compress={true}
          />
        </div>
      </div>
      <div>
        <FieldLabel label="Blog Content" />
        <CustomJoditEditor
          initialContent={formState.content}
          onChange={(content) =>
            handleChange({
              target: { value: content, name: "content" },
            })
          }
        />
        <div className="mt-4 w-fit">
          <Button name="Save" action={handleSubmit} />
        </div>
      </div>
    </div>
  );
}
