import React from "react";
import { NavLink } from "react-router-dom";
import menuStyle from "../../style/menuStyle.module.css";
import Navbar from "./Navbar";

function Header() {


  return (
    <div className="fixed top-0 w-full flex items-center justify-between bg-brandColor h-[50px] shadow-lg z-50">
      <NavLink to="/" className={menuStyle.brandLogo}></NavLink>
      <div style={{maxWidth:"40%", height:"100%", display:"flex", justifyContent:"space-between", alignItems:"center"}}>


        <Navbar />
      </div>
    </div>
  );
}

export default Header;
