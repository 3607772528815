import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { TextLinkButton } from "../../components/buttons";
import { SearchField } from "../../components/inputs";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { userListRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function UserList({ userType }) {
  useDocumentTitle(
    userType === "Dealer"
      ? "Dealer List"
      : userType === "Verified Seller"
      ? "Private Seller List"
      : "User List"
  );
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, users, success, error } = useSelector(
    (state) => state.userList
  );
  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(userListRequest(userType, keyword, pageNum, pageSize));
  }, [dispatch, pageNum, keyword, userType]);

  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          {userType === "Dealer" ? (
            <NavLink underline="hover" color="inherit" to="/users/dealer">
              Dealer List
            </NavLink>
          ) : userType === "Verified Seller" ? (
            <NavLink
              underline="hover"
              color="inherit"
              to="/users/private-seller"
            >
              Private Seller List
            </NavLink>
          ) : (
            <NavLink underline="hover" color="inherit" to="/users">
              Users
            </NavLink>
          )}
        </Breadcrumbs>
        <ScreenTitle
          title={
            userType === "Dealer"
              ? "Dealer List"
              : userType === "Verified Seller"
              ? "Private Seller List"
              : "User List"
          }
        />
        <div className="w-1/2 mt-8">
          <SearchField
            placeholder={`Type user name, email ${
              userType === "Dealer" ? ", company name " : ""
            } to search...`}
            initialQuery={keyword}
            onChange={handleSearchChange}
          />
        </div>

        {loading ? (
          <Loader mess="Requesting users..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  {userType === "Dealer" && <th>Company Name</th>}
                  <th>User Name</th>
                  <th>Email</th>

                  <th>Role</th>
                  <th>Status</th>
                  <th>Activated</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {users.users.length > 0 ? (
                  users.users.map((item, index) => (
                    <tr key={index}>
                      {userType === "Dealer" && <td>{item.companyName}</td>}
                      <td>
                        {item.userName}
                        {item.created_by_admin_id > 0 ? (
                          <p className="text-xs mt-2 w-fit text-white bg-brandColor rounded-full px-3 py-1">
                            WatchSpace Added
                          </p>
                        ) : (
                          <></>
                        )}
                      </td>
                      <td>{item.email}</td>

                      <td>{item.role}</td>
                      <td>{item.verifStatus}</td>
                      <td>{item.activated === 1 ? "Yes" : "No"}</td>
                      <td>
                        <TextLinkButton
                          name="View Details"
                          path={`/users/details/${item.id}`}
                        />
                        <div className="w-full my-4">
                          {item.created_by_admin_id ===
                          userInfo.data.user_id ? (
                            <TextLinkButton
                              name="Update"
                              path={`/users/edit/${item.id}`}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no user available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {users.users.length > 0 ? (
              <Pagination
                totalPageNum={users.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
