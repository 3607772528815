import {
  LOGIN_FAIL,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_REQUEST,
  USER_DETAILS_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_LIST_FAIL,
  USER_LIST_REQUEST,
  USER_LIST_SUCCESS,
} from "../constants/userConstants";
import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { ORDER_DETAILS_FAIL, ORDER_DETAILS_REQUEST, ORDER_DETAILS_SUCCESS, ORDER_LIST_FAIL, ORDER_LIST_REQUEST, ORDER_LIST_SUCCESS } from "../constants/orderConstants";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;


export const orderListRequest =
  (keyword, pageNum, pageSize) => async (dispatch, getState) => {
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
      },
    };
    try {
      dispatch({ type: ORDER_LIST_REQUEST });

      axios
        .get(
          SERVER_URL +
            `/admin/order/list?page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`,
          config
        )
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: ORDER_LIST_SUCCESS, payload: response.data.data });
          } else {
            throw response.data.message;
          }
        })
        .catch((err) => {
          dispatch({
            type: ORDER_LIST_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: ORDER_LIST_FAIL,
        payload: "Connection error, please try again later",
      });
    }
  };

export const orderDetailsRequest = (id) => async (dispatch, getState) => {
  const {
    userLogin: { userInfo },
  } = getState();

  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };
  try {
    dispatch({ type: ORDER_DETAILS_REQUEST });

    axios
      .get(SERVER_URL + `/admin/order/details/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: ORDER_DETAILS_SUCCESS, payload: response.data.data });
        } else {
          throw response.data.message;
        }
      })
      .catch((err) => {
        dispatch({
          type: ORDER_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};
