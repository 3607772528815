import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getContentDetailsRequest,
  updateContentRequest,
} from "../../api/webContentRequets";
import ScreenTitle from "../../components/ScreenTitle";
import { Button } from "../../components/buttons";
import { SimpleDialog } from "../../components/dialogs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import CustomJoditEditor from "../blog/CustomJoditEditor";


export default function AgreementEdit() {
  useDocumentTitle("Agreement Edit");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  //  dialog
  const [dialog, setDialog] = useState(false);
  const [name, setName] = useState();
  const [content, setContent] = useState();

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const getDetails = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getContentDetailsRequest(id, config);

        if (responseData.data.success) {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
          setName(responseData.data.data.name)
          setContent(responseData.data.data.content);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getDetails();
  }, []);

  const updateContent = async () => {
    try {
      setSaveStatus({ ...saveStatus, loading: true });

      let values = {
        content: content,
        name: name
      };
      const responseData = await updateContentRequest(id, values, config);

      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
        setDialog(true);
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/web-content/agreements", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update Agreement"
        successMessage="Agreement updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/web-content/agreements">
             Agreements
            </NavLink>
            <NavLink
              underline="hover"
              color="text.primary"
              to={`/web-content/agreement/edit/${id}`}
              aria-current="page"
            >
              Edit Agreement
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title={name ? name : "Edit Agreement"} />

        {requestStatus.success ? (
          <div>
            <CustomJoditEditor
              initialContent={content}
              onChange={(content) => setContent(content)}
            />

            <div className="mt-4 w-fit">
              <Button name="Save" action={updateContent} />
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
