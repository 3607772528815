

import { SelectField, TextField } from "./inputs";
import { K_COUNTRIES } from "../constantData/K_COUNTRIES";
import { useLoadScript } from "@react-google-maps/api";
import { useEffect, useRef, useState } from "react";

const libraries = ["places"];
const G_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export default function AddressCollector({ formState, onChange, errors }) {
  const [selectedPlace, setSelectedPlace] = useState();
  const handleChange = (e) => {
    const { name, value } = e.target;
    let copy = { ...formState };
    copy[name] = value;
    onChange(copy);
  };

  const handleAddressLineChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    let name = "streetLine1";
    let copy = { ...formState };
    copy[name] = value;
    onChange(copy);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: G_API_KEY,
    libraries,
  });

  const autoCompleteRef = useRef();
  const inputRef = useRef(null);

  useEffect(() => {
    if (isLoaded) {
      autoCompleteRef.current = new window.google.maps.places.Autocomplete(
        inputRef.current,
        {
          types: ["address"],
          componentRestrictions: { country: ["us", "ca"] },
        }
      );

      autoCompleteRef.current.addListener("place_changed", async function () {
        const place = await autoCompleteRef.current.getPlace();
        setSelectedPlace(place)
       // mapAddress(place);
      });
    }
  }, [isLoaded]);

  useEffect(() => {
    if(selectedPlace){
      let streetLine1 = "";
      let city = "";
      let region = "";
      let country = "";
      let postalCode = "";
  
      selectedPlace.address_components.forEach((component) => {
        if (component.types.includes("locality")) {
          city = component.long_name;
        }
        if (component.types.includes("country")) {
          let formattedCountry = K_COUNTRIES.filter(
            (x) => x.label === component.long_name
          )[0];
          country = formattedCountry;
        }
        if (component.types.includes("postal_code")) {
          postalCode = component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          region = component.long_name;
        }
        if (component.types.includes("street_number")) {
          streetLine1 = component.long_name;
        }
        if (component.types.includes("route")) {
          streetLine1 = streetLine1 + " " + component.long_name;
        }
        if (component.types.includes("sublocality_level_1")) {
          streetLine1 = streetLine1 + " " + component.long_name;
        }
      });
  
      let copy = { ...formState };
      copy["streetLine1"] = streetLine1;
      copy["city"] = city;
      copy["region"] = region;
      copy["country"] = country;
      copy["postalCode"] = postalCode;
  
      onChange(copy);

    }
   
  }, [selectedPlace]);


  return (
    <div className="w-full">
      <div className="w-full flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 lg:mr-8">
          <TextField
            type="text"
            name={"firstName"}
            label="First Name*"
            value={formState.firstName}
            onChange={(firstName) =>
              handleChange({
                target: { value: firstName, name: "firstName" },
              })
            }
            error={errors.firstName}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <TextField
            type="text"
            name={"lastName"}
            label="Last Name*"
            value={formState.lastName}
            onChange={(lastName) =>
              handleChange({
                target: { value: lastName, name: "lastName" },
              })
            }
            error={errors.lastName}
          />
        </div>
      </div>
      <TextField
        type="text"
        label="Phone*"
        name={"phone"}
        value={formState.phone}
        onChange={(phone) =>
          handleChange({
            target: { value: phone, name: "phone" },
          })
        }
        error={errors.phone}
      />

      <div className="relative w-full mt-6">
        <label className="w-full text-base text-black">Street Line*</label>
        <div className="relative flex items-center w-full mt-2">
          <input
            ref={inputRef}
            type={"text"}
            name={"streeline"}
            value={formState.streetLine1}
            onChange={handleAddressLineChange}
            placeholder={""}
            className={`w-full h-12 pl-4 py-2 rounded border-solid border-borderGray border-2 hover:border-black focus:outline-black focus:border-black active:outline-black`}
          ></input>
        </div>
        {errors.streetLine1 ? (
          <p className="w-full text-red-500 mt-2 ">{errors.streetLine1}</p>
        ) : (
          <></>
        )}
      </div>
      <div className="w-full flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 lg:mr-8">
          <TextField
            type="text"
            name={"city"}
            label="City*"
            value={formState.city}
            onChange={(city) =>
              handleChange({
                target: { value: city, name: "city" },
              })
            }
            error={errors.city}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <TextField
            type="text"
            label="State"
            name={"region"}
            value={formState.region}
            onChange={(region) =>
              handleChange({
                target: { value: region, name: "region" },
              })
            }
          />
        </div>
      </div>
      <div className="w-full flex flex-col lg:flex-row">
        <div className="w-full lg:w-1/2 lg:mr-8">
          <SelectField
            id="country_select"
            label="Country*"
            placeHolder="Select country"
            selectOptions={K_COUNTRIES}
            value={formState.country}
            onChange={(country) =>
              handleChange({
                target: { value: country, name: "country" },
              })
            }
            error={errors.country}
          />
        </div>
        <div className="w-full lg:w-1/2">
          <TextField
            type="text"
            name={"postalCode"}
            label="Postal Code*"
            value={formState.postalCode}
            onChange={(postalCode) =>
              handleChange({
                target: { value: postalCode, name: "postalCode" },
              })
            }
            error={errors.postalCode}
          />
        </div>
      </div>
    </div>
  );
}
