import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import useDocumentTitle from "../../useDocumentTitle";
import ScreenTitle from "../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import { SimpleDialog } from "../../components/dialogs";
import { useDispatch, useSelector } from "react-redux";
import { updateBrandRequest } from "../../redux/actions/watchActions";
import BrandForm from "./BrandForm";

export default function NewBrand() {
  useDocumentTitle("Add New Brand");

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //const loading = false;
  const { loading, success, error } = useSelector((state) => state.newBrand);

  // // // // // //

  const handleSubmit = (values) => {
    setDialog(true);
    dispatch(updateBrandRequest(values));
  };

  //  dialog
  const [dialog, setDialog] = useState(false);

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/watch/brands", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="New Brand"
        successMessage="New brand added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/watch/brands">
            Watch Brands
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/watch/brand/new`}
            aria-current="page"
          >
            New Brand
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Brand" />

        <div className="w-1/2">
          <BrandForm
            initialState={{ name: "", imgeUri: "" }}
            onSave={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
