import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { getContentDetailsRequest } from "../../api/webContentRequets";
import ScreenTitle from "../../components/ScreenTitle";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import CareerForm from "./CareerForm";

const apiUrl = process.env.REACT_APP_API_URL;

export default function CareerEdit() {
  useDocumentTitle("Edit Position");
  const { id: contentId } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [content, setContent] = useState("");

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const getDocDetails = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getContentDetailsRequest(contentId, config);

        if (responseData.data.success) {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
          let details = responseData.data.data;

          setContent({
            name: details.name,
            content: details.content,
          });
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getDocDetails();
  }, []);

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/web-content/career">
              Career
            </NavLink>
            <NavLink
              underline="hover"
              color="text.primary"
              to={`/web-content/career/edit/${contentId}`}
              aria-current="page"
            >
              Edit Position
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title="Edit Position" />

        {requestStatus.success ? (
          <CareerForm
            initialContent={content}
            contentId={contentId}
            type="edit"
          />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
