import { Breadcrumbs } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getAgreementListRequest } from "../../api/webContentRequets";
import ScreenTitle from "../../components/ScreenTitle";
import { EditLinkButton } from "../../components/buttons";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";


export default function AgreementList() {
  const dispatch = useDispatch();
  useDocumentTitle("Agreements");

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [list, setList] = useState([]);

  useEffect(() => {
    const getDocTitles = async () => {
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await getAgreementListRequest("agreement", config, dispatch);

        if (responseData.data.success) {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
          setList(responseData.data.data);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: "error",
        }));
      }
    };
    getDocTitles();
  }, []);

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/web-content/agreements">
             Agreements
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title="Agreements" />
        {requestStatus.success ? (
          <div className={commonStyle.shortDataTable}>
            <div className="mt-8">
              <table className={commonStyle.pageTable}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {list.length > 0 ? (
                    list.map((item, index) => (
                      <tr key={index}>
                       
                        <td>{item.name}</td>
                    
                        <td>
                        <EditLinkButton path={`/web-content/agreements/edit/${item.id}`} data={item}/>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td>
                        There are no data available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
