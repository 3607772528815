import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

// check if user has logged in and has access to visit url

function RequireAuth({
  children,
  pageName,
  permisson,
}) {

  const { userInfo } = useSelector((state) => state.userLogin);
  const success = true;
  // const { userInfo } = useSelector((state) => state.userLogin);
  // const { userDetails, success } = useSelector((state) => state.userDetail);

  let location = useLocation();

  // check user login status
  if (!userInfo) {
    // Redirect them to the /login page
    return <Navigate to="/login" state={{ from: location }} replace />;
  } else if (permisson) {
    if (!success) {

      return (
        <Navigate to="/no_permission" state={{ from: location }} replace />
      );
      // let roles = ["aa"]
      // // let roles = userDetails.data.permissions;
      // let hasAccess = roles.includes(pageName);

      // if (!hasAccess) {
       
      // }
    }
  } else {
    return children;
  }

  return children;
}

export { RequireAuth };
