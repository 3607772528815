import { Breadcrumbs } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { updateImageRequest } from "../../api/webContentRequets";
import ScreenTitle from "../../components/ScreenTitle";
import SingleImageUploader from "../../components/SingleImageUploader";
import { Button } from "../../components/buttons";
import { SimpleDialog } from "../../components/dialogs";
import { FieldLabel } from "../../components/inputs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

const apiUrl = process.env.REACT_APP_API_URL;

export default function ImageEdit() {
  const { id: imageId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const imageDetails = location.state;

  useDocumentTitle("Web Image List");

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [image, setImage] = useState(imageDetails.image);
  const [imageError, setImageError] = useState("");

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  //  dialog
  const [dialog, setDialog] = useState(false);

  const getUploadedImages = (file) => {
    if (file.uri) {
      setImage(file.uri);
    }
  };

  const updateImage = async () => {
    try {
      setSaveStatus({ ...saveStatus, loading: true });

      let values = {
        image: image,
      };

      const responseData = await updateImageRequest(imageId, values, config);

      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
        setDialog(true);
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };
  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/web-content/images", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Update Image"
        successMessage="Image updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/web-content/images">
              Website Images
            </NavLink>
            <NavLink
              underline="hover"
              color="inherit"
              to={`/web-content/images/${imageId}`}
            >
              Edit
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title="Edit Image" />

        <div className="w-full">
          <FieldLabel label={imageDetails.name} />

          <div className="w-full h-[360px]">
            <SingleImageUploader
              onUpload={getUploadedImages}
              imageError={imageError}
              uri={image}
              folderName="webcontent"
              compress={true}
            />
          </div>

          <div className="mt-8 w-full ">
            <Button name="Save" action={updateImage} />
          </div>
        </div>
      </div>
    </div>
  );
}
