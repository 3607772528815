import React, { useEffect, useState, useRef } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import { useNavigate, useLocation } from "react-router-dom";
import useDocumentTitle from "../../useDocumentTitle";
import ScreenTitle from "../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import { SimpleDialog } from "../../components/dialogs";
import { SelectField, TextField } from "../../components/inputs";
import { useDispatch, useSelector } from "react-redux";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { Button } from "../../components/buttons";
import {
  brandOptionsListRequest,
  newModelRequest,
} from "../../redux/actions/watchActions";


export default function NewModel() {
  useDocumentTitle("Add New Model");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //const loading = false;

  const {
    loading: brandsLoading,
    brandOptions,
    success: brandsSuccess,
    error: brandsError,
  } = useSelector((state) => state.brandOptionList);

  const { loading, success, error } = useSelector((state) => state.newModel);

  const [formState, setFormState] = useState({
    brand:"",
    name: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  // // // // // //
  useEffect(() => {
    dispatch(brandOptionsListRequest());
  }, [dispatch]);

  // // // // // //

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      setDialog(true);
      let modelvalues = {
        name: formState.name,
        brandId: formState.brand.id
      }
      dispatch(newModelRequest(modelvalues));
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.brand) {
      errors.brand = "Brand is required.";
    }
    if (!values.name) {
      errors.name = "Model name is required.";
    }

    return errors;
  };

  //  dialog
  const [dialog, setDialog] = useState(false);

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/watch/models", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="New Model"
        successMessage="New model added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/watch/models">
            Watch Models
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/watch/model/new`}
            aria-current="page"
          >
            New Brand
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Model" />
        {brandsLoading ? (
          <Loader mess="Requesting brand list..." />
        ) : brandsSuccess ? (
          <div className="w-1/2">
            <SelectField
              label="Brand"
              placeHolder="Select a brand"
              selectOptions={brandOptions}
              value={formState.brand}
              onChange={(brand) =>
                handleChange({
                  target: { value: brand, name: "brand" },
                })
              }
              error={formErrors.brand}
            />

            <TextField
              type="text"
              label="Model Name"
              name="name"
              autofocus={true}
              value={formState.name}
              onChange={(name) =>
                handleChange({
                  target: { value: name, name: "name" },
                })
              }
              error={formErrors.name}
              readOnly={loading ? true : false}
            />

            <div className="mt-8 w-full">
              <Button name="Add New Watch Model" action={handleSubmit} />
            </div>
          </div>
        ) : brandsError ? (
          <ErrorMessage mess={brandsError} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
