import { TailSpin } from "react-loader-spinner";
import { NavLink } from "react-router-dom";
import { convtPrice } from "../api/functions";
import { AiOutlineQuestionCircle, AiOutlineArrowLeft } from "react-icons/ai";
import { BsFuelPump, BsHouseDoor, BsSpeedometer2 } from "react-icons/bs";
import { LiaCarBatterySolid } from "react-icons/lia";


const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

function ErrorMessage({ mess }) {
  return (
    <div className="min-h-12 bg-red-100  text-red-600 flex items-center border border-red-400 rounded px-3 py-3 w-full mt-4 mb-4">
      {mess !== null && typeof mess === "object" ? mess.message : mess}
    </div>
  );
}

function SuccessMessage({ mess }) {
  return (
    <div className="min-h-12 bg-green-100  text-green-600 flex items-center border border-green-400 rounded px-3 py-3 w-full ">
      {mess}
    </div>
  );
}

function Loader({ mess }) {
  return (
    <div className="px-3 py-3 flex items-center">
      <TailSpin color="black" height={20} width={20} />
      <p className="text-black ml-2">{mess}</p>
    </div>
  );
}

function UserAvatar({ name }) {
  const displayName = name.charAt(0).toUpperCase();
  const a = ["A", "C", "E", "G", "I", "K", "M", "O", "Q", "S", "U", "W", "Y"];
  const b = ["B", "D", "F", "H", "J", "L", "N", "P", "R", "T", "V", "X", "Z"];
  return (
    <div
      className={`rounded-full ${
        a.includes(displayName) ? "bg-brandYellow" : "bg-brandYellowHover"
      } w-12 h-12 flex items-center justify-center`}
    >
      {displayName}
    </div>
  );
}

function OrderStatusTag({ currState }) {
  let statusName = "Default";
  let tagColor = "gray";

  if (currState === "NEW") {
    statusName = "Order Placed";
    tagColor = "green";
  } else if (currState === "CANCELLED") {
    statusName = "Order Cancelled";
    tagColor = "gray";
  } else if (currState === "INSPECTION_FAILED") {
    statusName = "Inspection Failed";
    tagColor = "gray";
  } else if (currState === "WAITING_OFFLINE_PAYMENT") {
    statusName = "Awaiting Payment";
    tagColor = "green";
  } else if (currState === "OFFLINE_PAYMENT_RECEIVED") {
    statusName = "Payment Received";
    tagColor = "green";
  } else if (currState === "SHIPPED_PRODUCT_TO_BUYER") {
    statusName = "Product Shipped";
    tagColor = "green";
  } else if (currState === "WAITING_ONLINE_PAYMENT") {
    statusName = "Awaiting Service Fee Payment";
    tagColor = "green";
  } else if (currState === "ONLINE_PAYMENT_RECEIVED") {
    statusName = "Service Fee Paid";
    tagColor = "green";
  } else if (currState === "SHIPPING_LABEL_SEND") {
    statusName = "Shipping Label Send";
    tagColor = "green";
  } else if (currState === "WAITING_PRODUCT_FROM_SELLER") {
    statusName = "Awaiting Product send to WatchSpace";
    tagColor = "green";
  } else if (currState === "PRODUCT_RECEIVED_BY_PLATFORM") {
    statusName = "Product Under Inspection";
    tagColor = "green";
  } else if (currState === "WAITING_INSPECTION_RESULT_CONFIRM") {
    statusName = "Awaiting Confirm Inspection Result";
    tagColor = "green";
  } else if (currState === "WAITING_OFFLINE_PAYMENT") {
    statusName = "Awaiting Product Payment";
    tagColor = "green";
  } else if (currState === "OFFLINE_PAYMENT_RECEIVED") {
    statusName = "Product Payment Received";
    tagColor = "green";
  } else if (currState === "SHIPPED_PRODUCT_TO_BUYER") {
    statusName = "Product Shipped";
    tagColor = "green";
  } else if (currState === "COMPLETED") {
    statusName = "Completed";
    tagColor = "gray";
  } else {
    statusName = currState;
    tagColor = "gray";
  }

  return (
    <span
      className={`text-sm px-3 rounded text-green ${
        tagColor === "gray"
          ? "text-gray"
          : tagColor === "green"
          ? "text-green-500"
          : "text-gray"
      }`}
    >
      {statusName}
    </span>
  );
}

function ProductStatusTag({ currState }) {
  let statusName = "Default";
  let tagColor = "gray";

  if (currState === "Active") {
    tagColor = "green";
  } else if (currState === "SOLD") {
    tagColor = "gray";
  } else if (currState === "Suspended") {
    tagColor = "red";
  } else {
    tagColor = "gray";
  }

  return (
    <span
      className={`text-sm px-3 rounded text-green ${
        tagColor === "gray"
          ? "text-gray"
          : tagColor === "green"
          ? "text-green-500"
          : tagColor === "green"
          ? "text-red-500"
          : "text-gray"
      }`}
    >
      {currState}
    </span>
  );
}

function ProductName({ image, title, subtitle, price }) {
  return (
    <div className="flex p-4 min-w-[280px]">
      <div className="w-20 h-20">
        <img
          alt={image ? image : "default-watch"}
          src={image ? S3_DOMAIN + image : "/default-car.svg"}
          className="w-full h-full object-cover"
          style={{
            maxWidth: "100%",
            maxHeight: "100%",
          }}
        />
      </div>
      <div className="ml-6">
        <p>{title}</p>
        <p className="mt-1 text-gray">{subtitle}</p>
        <p className="mt-4">${convtPrice(price)}</p>
      </div>
    </div>
  );
}

function NoDataChart({ text }) {
  return (
    <div className="w-full h-full flex items-center justify-center flex-col bg-lightbg py-8 rounded">
      <AiOutlineQuestionCircle className="text-gray w-12 h-12" />
      <p className="mt-4 text-gray">{text}</p>
    </div>
  );
}

function H1({ title }) {
  return <h1 className="text-2xl lg:text-4xl font-semibold mt-2">{title}</h1>;
}

function H2({ title, subText = "" }) {
  return (
    <div className="flex items-center">
      <h2 className="text-xl lg:text-3xl font-medium mr-2">{title}</h2>
      <span className="text-gray">{subText}</span>
    </div>
  );
}
function H3({ title }) {
  return <h3 className="text-lg lg:text-xl font-medium">{title}</h3>;
}

function H4({ title }) {
  return <h4 className="text-base font-semibold">{title}</h4>;
}

function HightlightKeywords({ keywords, text }) {
  // Split text on highlight term, include term itself into parts, ignore case
  let parts = [];
  if (keywords && text) {
    parts = text.split(new RegExp(`(${keywords})`, "gi"));
  }

  return (
    <span>
      {keywords ? (
        <>
          {parts.map((part, index) =>
            part.toLowerCase() === keywords.toLowerCase() ? (
              <span key={index} className="text-brandYellow font-bold">
                {part}
              </span>
            ) : (
              part
            )
          )}
        </>
      ) : (
        text
      )}
    </span>
  );
}

function CarBodayTag({ name }) {
  let lowercaseName = name.toLowerCase();
  return (
    <span
      className={`px-2 py-1 rounded ${
        lowercaseName === "sedan"
          ? "bg-black"
          : lowercaseName === "station Wagon"
          ? "bg-green-500"
          : lowercaseName === "coupe"
          ? "bg-[#FF0000]"
          : lowercaseName === "convertible"
          ? "bg-[#077ADE]"
          : lowercaseName === "suv"
          ? "bg-green-500"
          : "bg-brandColor"
      } text-white mr-2`}
    >
      {name}
    </span>
  );
}

function EngineIcon({ name }) {
  return (
    <div className="w-full flex items-center">
      <img
        alt="select icon"
        src="/engine-icon.svg"
        style={{ width: "16px", height: "16px" }}
      />

      <span className="text-sm text-gray pl-1">{name}</span>
    </div>
  );
}

function TransmissionIcon({ name }) {
  return (
    <div className="w-full flex items-center">
      <img
        alt="select icon"
        src="/transmission-icon.svg"
        style={{ width: "16px", height: "16px" }}
      />

      <span className="text-sm text-gray ml-1">{name}</span>
    </div>
  );
}

function BatteryIcon({ name }) {
  return (
    <div className="w-full flex items-center">
      <LiaCarBatterySolid className="w-4 h-4 text-gray" />
      <span className="text-sm text-gray ml-1">{name}</span>
    </div>
  );
}

function DriveIcon({ name }) {
  return (
    <div className="w-full flex items-center">
      <img
        alt="select icon"
        src="/drive-icon.svg"
        style={{ width: "16px", height: "16px" }}
      />

      <span className="text-sm text-gray ml-1">{name}</span>
    </div>
  );
}
function FuelTypeIcon({ name }) {
  return (
    <div className="w-full flex items-center">
      <BsFuelPump className="w-4 h-4 text-gray" />
      <span className="text-sm text-gray ml-1">{name}</span>
    </div>
  );
}
function OdometerIcon({ name }) {
  return (
    <div className="w-full flex items-center">
      <BsSpeedometer2 className="w-4 h-4 text-gray" />
      <span className="text-sm text-gray ml-1">{name}</span>
    </div>
  );
}
function FormattedText({ text }) {
  let formatedText = text.split(/\n/).map((line, index) => {
    line = line.trim();
    return line ? <p key={index}>{line}</p> : <br key={index} />;
  });

  return <>{formatedText}</>;
}

export {
  ErrorMessage,
  SuccessMessage,
  Loader,
  UserAvatar,
  OrderStatusTag,
  ProductName,
  ProductStatusTag,
  NoDataChart,
  H1,
  H2,
  H3,
  H4,
  HightlightKeywords,
  OdometerIcon,
  FuelTypeIcon,
  DriveIcon,
  BatteryIcon,
  TransmissionIcon,
  EngineIcon,
  CarBodayTag,
  FormattedText
};
