import React, { useState, useEffect } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { MdClose } from "react-icons/md";
import { FaFileImage } from "react-icons/fa";
import { useSelector } from "react-redux";
import { uploadFileRequest, uploadFile } from "../api/fileUpload";
import { ErrorMessage, Loader } from "./smallComponents";
import { CloseButton } from "./buttons";

export default function FilesUploader({ initialFiles, onUpload }) {
  const S3_DOMAIN = process.env.S3_DOMAIN;

  const { userInfo } = useSelector((state) => state.userLogin);

  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState(initialFiles);
  const [fileError, setFileError] = useState("");

  useEffect(() => {
    onUpload(uploadedFiles);
  }, [uploadedFiles]);

  // triggers when file is dropped
  const dropHandler = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setFileError("");

    let allowedFiles = [];

    if (ev.dataTransfer && ev.dataTransfer.items) {
      const files = [...ev.dataTransfer.items]
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile());

      allowedFiles = files.filter(
        (file) =>
          file.type.startsWith("image/") || file.type === "application/pdf"
      );
    } else if (ev.target.files) {
      const files = [...ev.target.files];
      allowedFiles = files.filter(
        (file) =>
          file.type.startsWith("image/") || file.type === "application/pdf"
      );
    }

    if (allowedFiles.length === 0) {
      setFileError("Only image or pdf files accepted.");
      return;
    }
    setDragActive(false);

    let uploadedFilesCopy = [...uploadedFiles];
    // remove current error images
    uploadedFilesCopy = uploadedFilesCopy.filter((x) => x.uri);

    // Filter out files with duplicate names
    let uniqueFiles = allowedFiles.filter(
      (file) => !uploadedFilesCopy.some((x) => x.fileName === file.name)
    );

    uniqueFiles.map((file) =>
      uploadedFilesCopy.push({
        fileName: file.name,
        uri: "",
        isLoading: true, // Initially set loading to true
        error: "",
      })
    );

    setUploadedFiles(uploadedFilesCopy);
    uploadFiles(uniqueFiles);
  };

  const dragOverHandler = (ev) => {
    ev.preventDefault();
    setDragActive(true);
  };

  const deleteFile = (index) => {
    setUploadedFiles((prevFiles) => prevFiles.filter((file, i) => i !== index));
  };

  const updateFileStatus = (fileName, uri, error) => {
    setUploadedFiles((prevFiles) =>
      prevFiles.map((file) => {
        if (file.fileName === fileName) {
          return { ...file, uri, isLoading: false, error };
        }
        return file;
      })
    );
  };

  const uploadFiles = async (files) => {
    for (let file of files) {
      const formData = new FormData();
      formData.append("image", file);
      formData.append("folderName", "orderFiles");

      try {
        const responseData = await uploadFile(formData, config);
        if (responseData.data.success) {
          updateFileStatus(file.name, responseData.data.data.url, "");
        } else {
          if (typeof responseData.data.message === "string") {
            throw responseData.data.message;
          } else {
            throw "Failed to upload";
          }
        }
      } catch (error) {
        updateFileStatus(file.name, "", error);
      }
    }
  };

  return (
    <div>
      {fileError ? <ErrorMessage mess={fileError} /> : <></>}
      <p>Attachment</p>

      {uploadedFiles.map((file, index) => (
        <div key={index} className="">
          {file.isLoading ? (
            <div>
              <Loader />
            </div>
          ) : file.uri ? (
            <div className="block w-full h-full flex items-center mt-2">
              <a
                href={file.uri}
                download={file.fileName}
                className="text-blue-500 hover:text-blue-700"
              >
                {file.fileName}
              </a>

              <MdClose
                onClick={() => deleteFile(index)}
                className="w-5 h-5 text-red-500 ml-2"
              />
            </div>
          ) : file.error ? (
            <div>
              <ErrorMessage mess={file.error} />
            </div>
          ) : (
            <></>
          )}
        </div>
      ))}

      <div
        className="w-full text-center relative mt-4"
        onDrop={dropHandler}
        onDragOver={dragOverHandler}
      >
        <input
          type="file"
          id="input-file-upload"
          className="hidden"
          accept="image/*,application/pdf"
          multiple
          onChange={dropHandler}
        />
        <label
          id="label-file-upload"
          htmlFor="input-file-upload"
          className={`flex w-full items-center justify-center h-full border rounded border-dashed hover:cursor-pointer ${
            dragActive ? "border-black" : "border-borderGray"
          }`}
        >
          <div className="flex flex-col items-center py-6 hover:cursor-pointer">
            <AiOutlineCloudUpload className="w-12 h-12 text-gray" />
            <div className="flex flex-col items-cente mt-2 hover:cursor-pointer">
              <span className="text-blue-500">Browse Files</span>
              <span className="text-gray">or drag and drop files here</span>
            </div>
          </div>
        </label>
      </div>
    </div>
  );
}
