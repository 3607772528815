import React, { useEffect, useState } from "react";
import {
  SelectField,
  TextAreaField,
  TextField,
} from "../../../components/inputs";
import { Button } from "../../../components/buttons";

export default function QuestionsForm({ type, initialValues, onSave }) {

  const questionTypes = [
    { id: 1, label: "Sell", value: "Sell" },
    { id: 2, label: "Buy", value: "Buy" },
  ];

  const [formState, setFormState] = useState({});

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  // // // // // //
  useEffect(() => {

    if(type === "edit"){
      setFormState({
        questionType:  questionTypes.filter((x)=> x.value === initialValues.questionType)[0],
        questionName: initialValues.questionName,
        answer: initialValues.answer,
  
      })
    }else{
      setFormState(initialValues);
    }

   
  }, [type, initialValues]);

  // // // // // //

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSave(formState);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.questionType) {
      errors.questionType = "Question type is required.";
    }
    if (!values.questionName) {
      errors.questionName = "Question name is required.";
    }
    if (!values.answer) {
      errors.answer = "Question answer is required.";
    }

    return errors;
  };


  return (
    <div className="w-full">
      <div className="w-1/2">
        <SelectField
          label="Type"
          placeHolder="Select type"
          selectOptions={questionTypes}
          value={formState.questionType }
          extractKey=""
          onChange={(questionType) =>
            handleChange({
              target: { value: questionType, name: "questionType" },
            })
          }
          error={formErrors.questionType}
        />

        <TextField
          type="text"
          label="Question"
          name="questionName"
          autofocus={true}
          value={formState.questionName}
          onChange={(questionName) =>
            handleChange({
              target: { value: questionName, name: "questionName" },
            })
          }
          error={formErrors.questionName}
        />
        <TextAreaField
          type="text"
          label="Answer"
          name="answer"
          rows={20}
          value={formState.answer}
          onChange={(answer) =>
            handleChange({
              target: { value: answer, name: "answer" },
            })
          }
          error={formErrors.answer}
        />

        <div className="mt-8 w-full">
          <Button name={type === "new" ? "Save" : "Update"} action={handleSubmit} />
        </div>
      </div>
    </div>
  );
}
