
import {userlogout} from "./userActions"

export function authMiddleware(response, dispatch) {
 
    if (!response.data.success) {
        if(response.data.error === -3){
            dispatch(userlogout());
            throw "Invalid session";
        }
        // if(response.data.error === -2){
        //     throw Object.assign(
        //         new Error("You do not have permission do this action"),
        //         { code: 1002 }
        //      );
        // }  
    }   
    return response;
}