import Breadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import { NavLink } from "react-router-dom";
import ScreenTitle from "../../components/ScreenTitle";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import BlogForm from "./BlogForm";

const apiUrl = process.env.REACT_APP_API_URL;

export default function NewBlog() {
  useDocumentTitle("New Blog");

  const newBlogContent = {
    title: "",
    metaDescription: "",
    metaTitle: "",
    author: "",
    blogImage: "",
    readTime: "",
    content: "",
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/blogs">
              Blog
            </NavLink>
            <NavLink
              underline="hover"
              color="text.primary"
              to={`/blog/new`}
              aria-current="page"
            >
              New Blog
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title="New Blog" />
        <BlogForm initialContent={newBlogContent} blogId={""} type="new"/>
      </div>
    </div>
  );
}
