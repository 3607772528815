import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { addNewProductRequest, getWatchDetailsRequest } from "../../api/productsRequests";
import { delaerListRequest } from "../../redux/actions/productActions";
import {
  watchConstantOptionsRequest
} from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function NewProduct() {
  useDocumentTitle("Add New Product");


  return (
    <div className={commonStyle.pageContainer}>
      AA
       </div>
  );
}
