
import { BRAND_LIST_FAIL, BRAND_LIST_REQUEST, BRAND_LIST_SUCCESS, BRAND_OPTION_LIST_FAIL, BRAND_OPTION_LIST_REQUEST, BRAND_OPTION_LIST_SUCCESS, DELETE_WATCH_FAIL, DELETE_WATCH_REQUEST, DELETE_WATCH_SUCCESS, EDIT_WATCH_FAIL, EDIT_WATCH_REQUEST, EDIT_WATCH_SUCCESS, MODEL_LIST_BY_BRAND_FAIL, MODEL_LIST_BY_BRAND_REQUEST, MODEL_LIST_BY_BRAND_SUCCESS, MODEL_LIST_FAIL, MODEL_LIST_REQUEST, MODEL_LIST_SUCCESS, NEW_BRAND_FAIL, NEW_BRAND_REQUEST, NEW_BRAND_SUCCESS, NEW_MODEL_FAIL, NEW_MODEL_REQUEST, NEW_MODEL_SUCCESS, NEW_WATCH_FAIL, NEW_WATCH_REQUEST, NEW_WATCH_SUCCESS, WATCH_CONSTANT_OPTIONS_FAIL, WATCH_CONSTANT_OPTIONS_REQUEST, WATCH_CONSTANT_OPTIONS_SUCCESS, WATCH_DETAILS_FAIL, WATCH_DETAILS_REQUEST, WATCH_DETAILS_SUCCESS, WATCH_LIST_FAIL, WATCH_LIST_REQUEST, WATCH_LIST_SUCCESS } from "../constants/watchConstants";

export const watchConstantOptionsReducer = (state = { watchOptions: {} }, action) => {
    switch (action.type) {
      case WATCH_CONSTANT_OPTIONS_REQUEST:
        return { loading: true, success: false };
      case WATCH_CONSTANT_OPTIONS_SUCCESS:
        return { loading: false, watchOptions: action.payload, success: true };
      case WATCH_CONSTANT_OPTIONS_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
};

export const newBrandReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_BRAND_REQUEST:
      return { loading: true, success: false };
    case NEW_BRAND_SUCCESS:
      return { loading: false, success: true };
    case NEW_BRAND_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newModelReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_MODEL_REQUEST:
      return { loading: true, success: false };
    case NEW_MODEL_SUCCESS:
      return { loading: false, success: true };
    case NEW_MODEL_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const brandListReducer = (state = {brands:[]}, action) => {
  switch (action.type) {
    case BRAND_LIST_REQUEST:
      return { loading: true, success: false };
    case BRAND_LIST_SUCCESS:
      return { loading: false, brands: action.payload, success: true };
    case BRAND_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const modelListReducer = (state = {models:[]}, action) => {
  switch (action.type) {
    case MODEL_LIST_REQUEST:
      return { loading: true, success: false };
    case MODEL_LIST_SUCCESS:
      return { loading: false, models: action.payload, success: true };
    case MODEL_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const brandOptionListReducer = (state = {brandOptions:[]}, action) => {
  switch (action.type) {
    case BRAND_OPTION_LIST_REQUEST:
      return { loading: true, success: false };
    case BRAND_OPTION_LIST_SUCCESS:
      return { loading: false, brandOptions: action.payload, success: true };
    case BRAND_OPTION_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const modelListByBrandReducer = (state = {modelOptions:[]}, action) => {
  switch (action.type) {
    case MODEL_LIST_BY_BRAND_REQUEST:
      return { loading: true, success: false };
    case MODEL_LIST_BY_BRAND_SUCCESS:
      return { loading: false, modelOptions: action.payload, success: true };
    case MODEL_LIST_BY_BRAND_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const newWatchReducer = (state = {}, action) => {
  switch (action.type) {
    case NEW_WATCH_REQUEST:
      return { loading: true, success: false };
    case NEW_WATCH_SUCCESS:
      return { loading: false, success: true };
    case NEW_WATCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};


export const watchListReducer = (state = {watches:[]}, action) => {
  switch (action.type) {
    case WATCH_LIST_REQUEST:
      return { loading: true, success: false };
    case WATCH_LIST_SUCCESS:
      return { loading: false, watches: action.payload, success: true };
    case WATCH_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const editWatchReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_WATCH_REQUEST:
      return { loading: true, success: false };
    case EDIT_WATCH_SUCCESS:
      return { loading: false, success: true };
    case EDIT_WATCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const deleteWatchReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_WATCH_REQUEST:
      return { loading: true, success: false };
    case DELETE_WATCH_SUCCESS:
      return { loading: false, success: true };
    case DELETE_WATCH_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

export const watchDetailsReducer = (state = {watchDetails:{}}, action) => {
  switch (action.type) {
    case WATCH_DETAILS_REQUEST:
      return { loading: true, success: false };
    case WATCH_DETAILS_SUCCESS:
      return { loading: false, watchDetails: action.payload, success: true };
    case WATCH_DETAILS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};