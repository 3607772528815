import React, { useState } from "react";
import { MdKeyboardArrowDown } from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";
import menuStyle from "../../style/menuStyle.module.css";

function SideMenuItem({ item }) {
  const location = useLocation();
  const [isSubMenuOpen, setSubMenuOpen] = useState(false);

  return item.children && item.children.length > 0 ? (
    // sidemen expanded, and item has childrens
    <div
      className={
        isSubMenuOpen
          ? `${menuStyle.sideMenuItemChildren} ${menuStyle.open}`
          : `${menuStyle.sideMenuItemChildren}`
      }
    >
      <div
        className={menuStyle.sideMenuItemTitle}
        onClick={() => setSubMenuOpen(!isSubMenuOpen)}
      >
        <div className={menuStyle.sideMenuItemName}>
        {item.icon}
          <span className={menuStyle.sideMenuItemTitleName}>
            {item.menuName}
          </span>
        </div>
        <MdKeyboardArrowDown className={menuStyle.toggleBtn} />
      </div>

      <div className={menuStyle.sideMenuContent}>
        {item.children.map((child, index) => (
          <NavLink
            to={child.path}
            exact="true"
            key={index}
            className={
              child.path === location.pathname
                ? `${menuStyle.subMenuItem} ${menuStyle.pathSelected}`
                : menuStyle.subMenuItem
            }
          >
            {child.menuName}
          </NavLink>
        ))}
      </div>
    </div>
  ) : (
    <NavLink
      to={item.path}
      exact="true"
      className={
        item.path === location.pathname
          ? `${menuStyle.sideMenuItemPlain} ${menuStyle.pathSelected}`
          : menuStyle.sideMenuItemPlain
      }
    >
      {item.icon}

      <div className={menuStyle.sideMenuItemNamePlain}>{item.menuName}</div>
    </NavLink>
  );
}

export default SideMenuItem;
