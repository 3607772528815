
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { newContentRequest, updateContentRequest } from "../../api/webContentRequets";
import { Button } from "../../components/buttons";
import { SimpleDialog } from "../../components/dialogs";
import { FieldLabel, TextField } from "../../components/inputs";
import commonStyle from "../../style/commonStyle.module.css";
import CustomJoditEditor from "../blog/CustomJoditEditor";



export default function CareerForm({ initialContent, contentId, type }) {
  const navigate = useNavigate();
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  //  dialog
  const [dialog, setDialog] = useState(false);

  const [formState, setFormState] = useState(initialContent);

  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };


  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      if(type==="new"){
        saveContent();
      }else{
        updateContent();
      }
      
      setFormErrors({});
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = "Position name is required.";
    }

    return errors;
  };

  const saveContent = async () => {
    try {
      setSaveStatus({ ...saveStatus, loading: true });

      const responseData = await newContentRequest(formState, "career", config);

      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
        setDialog(true);
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };

  const updateContent = async () => {
    try {
      setSaveStatus({ ...saveStatus, loading: true });

      const responseData = await updateContentRequest(contentId, formState, config);

      if (responseData.data.success) {
        setSaveStatus({ ...saveStatus, loading: false, success: true });
        setDialog(true);
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus({ ...saveStatus, loading: false, error: error });
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/web-content/career", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title={`${type === "new" ? "New" : "Update"} Position`}
        successMessage={`Position ${type === "new" ? "added" : "updated"} successfully.`}
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="flex">
        <div className="w-1/2">
          <TextField
            type="text"
            label="Position Name*"
            placeholder={"The position name"}
            name="name"
            autofocus={true}
            value={formState.name}
            onChange={(name) =>
              handleChange({
                target: { value: name, name: "name" },
              })
            }
            error={formErrors.name}
          />

   
        </div>
      
      </div>
      <div>
        <FieldLabel label="Position Content" />
        <CustomJoditEditor
          initialContent={formState.content}
          onChange={(content) =>
            handleChange({
              target: { value: content, name: "content" },
            })
          }
        />

        <div className="mt-4 w-fit">
          <Button name="Save" action={handleSubmit} />

        </div>

 
      </div>
    </div>
  );
}
