import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import {
  updateProductRequest,
  getProductEditDetails,
} from "../../api/productsRequests";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";
import { watchConstantOptionsRequest } from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import VehicleDetailsForm from "./VehicleDetailsForm";

export default function EditProduct() {
  useDocumentTitle("Edit Product");
  const { id: listingId } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [dealerId, setDealerId] = useState("");
  const [initialDetails, setInitialDetails] = useState({
    // step1
    makeId: "",
    year: "",
    modelId: "",
    generationId: "",
    transmissionId: "",
    fuelTypeId: "",
    specId: "",
    odometerUnit: "KM",
    odometer: "",
    plate: "",
    roadLegal: true,
    // step 2
    images: [],
    title: "",
    subtitle: "",
    price: "",
    description: "",
    region: "",
    city: "",
    email: "",
    phone: "",
    enableEmail: false,
    enablePhone: false,
    status: "DRAFT",
  });

  const [dialog, setDialog] = useState(false);

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [detailStatus, setDetailStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  useEffect(() => {
    const getListingDetails = async () => {
      try {
        setDetailStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getProductEditDetails(listingId, config);
        if (responseData.data.success) {
          let details = responseData.data.data;

          let newValues = {
            makeId: details.makeId,
            year: details.year,
            modelId: details.modelId,
            generationId: details.generationId,
            transmissionId: details.transmissionId,
            fuelTypeId: details.fuelTypeId,
            specId: details.specId,
            odometerUnit: details.odometerUnit,
            odometer: details.odometer,
            plate: details.plate,
            roadLegal: details.roadLegal === 1 ? true : false,
            title: details.title,
            subtitle: details.subtitle,
            price: details.price,
            description: details.description,
            images: details.images,
            status: details.status,
          };

          setInitialDetails(newValues);

          setDetailStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setDetailStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (listingId) {
      getListingDetails();
    }
  }, [listingId]);

  const submitListing = async (values) => {
    
    try {
      setSaveStatus((preState) => ({
        ...preState,
        loading: true,
      }));
      const responseData = await updateProductRequest(
        listingId,
        values,
        config
      );
      if (responseData.data.success) {
        setDialog(true);
        setSaveStatus((preState) => ({
          ...preState,
          loading: false,
          success: true,
        }));
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setSaveStatus((preState) => ({
        ...preState,
        loading: false,
        error: error,
      }));
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/products/details/${listingId}`);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Product"
        successMessage="Product updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={saveStatus.loading}
        loadingMessage="Processing request..."
        success={saveStatus.success}
        error={saveStatus.error}
        confirmAction={closeDialogWithSuccess}
      />
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/products/edit/${listingId}`}
            aria-current="page"
          >
            Edit Product
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Edit Product on Behalf of Dealer" />

        {detailStatus.loading ? (
          <Loader mess="Requeting watch options..." />
        ) : detailStatus.success ? (
          <VehicleDetailsForm
            type={"edit"}
            onSave={submitListing}
            submitLoading={saveStatus.loading}
            submitError={saveStatus.error}
            initialDetails={initialDetails}
            dealerId={dealerId}
          />
        ) : detailStatus.error ? (
          <ErrorMessage mess={detailStatus.error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
