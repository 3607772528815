import { TextAreaField, TextField } from "../../components/inputs";

export default function CompanyInfoCollector({ formState, onChange, errors }) {
  const handleChange = (e) => {
    const { name, value } = e.target;
    let copy = { ...formState };
    copy[name] = value;
    onChange(copy);
  };

  return (
    <div className="w-full">
      <div className="w-full flex flex-col">
        <TextField
          type="text"
          label="Company Name*"
          value={formState.companyName}
          onChange={(companyName) =>
            handleChange({
              target: { value: companyName, name: "companyName" },
            })
          }
          error={errors.companyName}
        />

        <TextField
          type="text"
          label="EIN Number(Optional)"
          value={formState.einNumber}
          onChange={(einNumber) =>
            handleChange({
              target: { value: einNumber, name: "einNumber" },
            })
          }
          error={errors.einNumber}
        />

        <TextField
          type="text"
          label="website(Optional)"
          value={formState.website}
          onChange={(website) =>
            handleChange({
              target: { value: website, name: "website" },
            })
          }
        />
        <TextAreaField
          type="text"
          label="Description"
          name="description"
          rows={6}
          value={formState.description}
          onChange={(description) =>
            handleChange({
              target: { value: description, name: "description" },
            })
          }
        />
      </div>
    </div>
  );
}
