// get package list
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const uploadImage = async (formData, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/image/upload`, formData, config);
    return response;
  } catch (error) {
    throw error;
  }

};

export const uploadFile = async (formData, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/file/upload`, formData, config);
    return response;
  } catch (error) {
    throw error;
  }

};
