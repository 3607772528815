import Breadcrumbs from "@mui/material/Breadcrumbs";
import React from "react";
import { NavLink } from "react-router-dom";
import ScreenTitle from "../../components/ScreenTitle";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import CareerForm from "./CareerForm";

const apiUrl = process.env.REACT_APP_API_URL;

export default function NewCareer() {
  useDocumentTitle("New Blog");

  const newContent = {
    name: "",
    content: "",
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/web-content/career">
              Career
            </NavLink>
            <NavLink
              underline="hover"
              color="text.primary"
              to={`/web-content/career/new`}
              aria-current="page"
            >
              New Position
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title="New Position" />
        <CareerForm initialContent={newContent} contentId={""} type="new"/>
      </div>
    </div>
  );
}
