import { Loader } from "./smallComponents";
import { NavLink } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { MdClose, MdOutlinePreview } from "react-icons/md";
import { AiOutlineQuestionCircle, AiOutlineArrowLeft } from "react-icons/ai";
import { MdDeleteOutline } from "react-icons/md";


function Button({ name, action }) {
  const handleClick = (e) => {
    e.preventDefault();
    action();
  };
  return (
    <button
      className="h-12 bg-brandYellow  text-black flex items-center justify-center rounded px-3 py-1 w-full hover:bg-brandYellowHover"
      onClick={handleClick}
    >
      {name}
    </button>
  );
}

function LoadingButton({ name }) {
  return (
    <div className="h-12 bg-brandYellowHover text-black flex items-center justify-center rounded px-3 py-1 w-full ">
      <Loader mess={name} />
    </div>
  );
}

function LinkButton({ name, path, data, width }) {
  return (
    <NavLink
      to={path}
      state={data}
      className={`h-12 bg-brandYellow  text-black flex items-center justify-center rounded px-3 py-1 ${
        width === "full" ? " w-full" : "w-auto"
      } hover:bg-brandYellowHover`}
    >
      {name}
    </NavLink>
  );
}

function CloseButton({ action }) {
  return (
    <button
      onClick={action}
      className="px-2 py-2 bg-lightGray rounded-full hover:bg-brandYellow focus:outline-none"
    >
      <MdClose className="w-4 lg:w-6 w-4 lg:h-6" />
    </button>
  );
}
function CloseLink({ to }) {
  return (
    <NavLink
      to={to}
      className="px-2 py-2 bg-lightGray rounded-full hover:bg-brandYellow focus:outline-none"
    >
      <MdClose className="w-4 lg:w-6 w-4 lg:h-6" />
    </NavLink>
  );
}

function EditLinkButton({ path, data = null }) {
  return (
    <NavLink
      to={path}
      state={data}
      className={` text-blue-500 hover:cursor-pointer`}
    >
      <FiEdit className="w-5 h-5 m-2" />
    </NavLink>
  );
}

function TextLinkButton({ name, path, data = null }) {
  return (
    <NavLink
      to={path}
      state={data}
      className="text-sm text-blue-500 hover:cursor-pointer"
    >
      {name}
    </NavLink>
  );
}

function SubmitButton({ name }) {
  return (
    <button
      type="submit"
      className="h-12 bg-brandYellow  text-black flex items-center justify-center rounded px-3 py-1 w-full hover:bg-brandYellowHover"
    >
      {name}
    </button>
  );
}

function BlackOutlineButton({ name, action, isActive = true, icon = "" }) {
  const handleClick = (e) => {
    e.preventDefault();
    action();
  };
  return isActive ? (
    <button
      className="h-12 border-black border text-black flex items-center justify-center rounded px-3 py-1 w-full hover:bg-black hover:text-white"
      onClick={handleClick}
    >
      {icon ? icon : <></>}
      <span className={`${icon ? "ml-2" : ""}`}>{name}</span>
    </button>
  ) : (
    <div className="h-12 bg-lightGray text-gray flex items-center justify-center rounded px-3 py-1 w-full">
      {name}
    </div>
  );
}

function BackButton({ name, action }) {
  const handleClick = (e) => {
    e.preventDefault();
    action();
  };
  return (
    <button
      className="h-12 w-2/5 bg-none underline py-2 text-black flex items-center justify-center hover:text-brandYellowHover mr-8"
      onClick={handleClick}
    >
      <AiOutlineArrowLeft />
      <span className="ml-2"> {name}</span>
    </button>
  );
}

function DeleteButton({action }) {
  const handleClick = (e) => {
    e.preventDefault();
    action();
  };
  return (
    <button
      className="h-12 w-2/5 bg-none underline py-2 text-red-500 flex items-center justify-center hover:text-brandYellowHover mr-8"
      onClick={handleClick}
    >
      <MdDeleteOutline className="w-5 h-5 m-2"/>
    </button>
  );
}

export {
  Button,
  LoadingButton,
  LinkButton,
  EditLinkButton,
  CloseButton,
  CloseLink,
  TextLinkButton,
  SubmitButton,
  BlackOutlineButton,
  BackButton,
  DeleteButton
};
