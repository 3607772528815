import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import Pagination from "../../components/Pagination";
import ScreenTitle from "../../components/ScreenTitle";
import { LinkButton, TextLinkButton } from "../../components/buttons";
import { SearchField } from "../../components/inputs";
import {
  ErrorMessage,
  Loader,
  ProductName,
  ProductStatusTag,
} from "../../components/smallComponents";
import { productListRequest } from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";

export default function ProductList() {
  useDocumentTitle("Product List");
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, products, success, error } = useSelector(
    (state) => state.productList
  );

  console.log(products);


  const pageSize = 12;
  const [pageNum, setPageNum] = useState(0);
  const [keyword, setKeyword] = useState("");

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(productListRequest(keyword, pageNum, pageSize));
  }, [dispatch, pageNum, keyword]);


  const handleSearchChange = (value) => {
    setKeyword(value);
    setPageNum(0);
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/products">
            Products
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Product List" />
        <div className="w-1/2 mt-8">
          <SearchField
            placeholder="Type brand,model, reference number to search..."
            initialQuery={keyword}
            onChange={handleSearchChange}
          />
        </div>

        {loading ? (
          <Loader mess="Requesting products..." />
        ) : success ? (
          <div className={commonStyle.longDataTable}>
            <table className={commonStyle.pageTable}>
              <thead>
                <tr>
                  <th>Product</th>
                  <th>Seller</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {products.listings.length > 0 ? (
                  products.listings.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <ProductName
                        title={item.title}
                        subtitle={item.subtitle}
                          image={item.image ? item.image : ""}
                   
                          price={item.price}
                        />
                      </td>

                      <td>
                        <div>
                          <p>{item.sellerName}</p>
                          {item.create_by_admin_id > 0 ? (
                            <p className="text-xs mt-2 w-fit text-white bg-brandColor rounded-full px-3 py-1">
                              WatchSpace Added
                            </p>
                          ) : (
                            <></>
                          )}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <ProductStatusTag currState={item.status} />
                      </td>
                      <td>
                        {/* <TextLinkButton
                          name="View Details"
                          path={`/products/details/${item.id}`}
                        /> */}

                        <div className="w-full my-4">
                        <TextLinkButton
                              name="Update Product"
                              path={`/products/edit/${item.id}`}
                            />
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td>There are no product available.</td>
                  </tr>
                )}
              </tbody>
            </table>
            {products.listings.length > 0 ? (
              <Pagination
                totalPageNum={products.totalPages}
                forcePage={pageNum}
                onPageChange={onPageChangeRequest}
              />
            ) : (
              <></>
            )}
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
        
      </div>
    </div>
  );
}
