
import axios from "axios";
import { authMiddleware } from "./authMiddleware";
import { BRAND_LIST_FAIL, BRAND_LIST_REQUEST, BRAND_LIST_SUCCESS, BRAND_OPTION_LIST_FAIL, BRAND_OPTION_LIST_REQUEST, BRAND_OPTION_LIST_SUCCESS, DELETE_WATCH_FAIL, DELETE_WATCH_REQUEST, DELETE_WATCH_SUCCESS, EDIT_WATCH_FAIL, EDIT_WATCH_REQUEST, EDIT_WATCH_SUCCESS, MODEL_LIST_BY_BRAND_FAIL, MODEL_LIST_BY_BRAND_REQUEST, MODEL_LIST_BY_BRAND_SUCCESS, MODEL_LIST_FAIL, MODEL_LIST_REQUEST, MODEL_LIST_SUCCESS, NEW_BRAND_FAIL, NEW_BRAND_REQUEST, NEW_BRAND_SUCCESS, NEW_MODEL_FAIL, NEW_MODEL_REQUEST, NEW_MODEL_SUCCESS, NEW_WATCH_FAIL, NEW_WATCH_REQUEST, NEW_WATCH_SUCCESS, WATCH_CONSTANT_OPTIONS_FAIL, WATCH_CONSTANT_OPTIONS_REQUEST, WATCH_CONSTANT_OPTIONS_SUCCESS, WATCH_DETAILS_FAIL, WATCH_DETAILS_REQUEST, WATCH_DETAILS_SUCCESS, WATCH_LIST_FAIL, WATCH_LIST_REQUEST, WATCH_LIST_SUCCESS } from "../constants/watchConstants";
const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const watchConstantOptionsRequest = (values) => async (dispatch, getState) => {

    const {
      userLogin: { userInfo },
    } = getState();
    
    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.data.token}`,
       
      },
    };
    try {
      dispatch({ type: WATCH_CONSTANT_OPTIONS_REQUEST });
  
      axios
        .get(SERVER_URL + `/admin/watch/constant_options`, config)
        .then(async (response) => authMiddleware(response, dispatch))
        .then(async (response) => {
          if (response.data.success) {
            dispatch({ type: WATCH_CONSTANT_OPTIONS_SUCCESS, payload: response.data.data });
          }else{
            throw response.data.message;
          }
      
        })
        .catch((err) => {
          dispatch({
            type: WATCH_CONSTANT_OPTIONS_FAIL,
            payload: err,
          });
        });
    } catch (error) {
      dispatch({
        type: WATCH_CONSTANT_OPTIONS_FAIL,
        payload: "Connection error, please try again later",
      });
    }
};

export const updateBrandRequest = (values, brandId = null) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: NEW_BRAND_REQUEST });

    axios
      .post(SERVER_URL + `/admin/watch/brand/update?brandId=${brandId}`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_BRAND_SUCCESS});
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: NEW_BRAND_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_BRAND_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const brandListRequest = (keyword, pageNum, pageSize) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: BRAND_LIST_REQUEST });

    axios
      .get(SERVER_URL + `/admin/watch/brand/list?page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: BRAND_LIST_SUCCESS, payload: response.data.data });
         
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: BRAND_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: BRAND_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const newModelRequest = (values) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: NEW_MODEL_REQUEST });

    axios
      .post(SERVER_URL + `/admin/watch/model/new`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_MODEL_SUCCESS});
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: NEW_MODEL_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_MODEL_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const modelListRequest = (pageNum, pageSize) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: MODEL_LIST_REQUEST });

    axios
      .get(SERVER_URL + `/admin/watch/model/list?page_num=${pageNum}&page_size=${pageSize}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: MODEL_LIST_SUCCESS, payload: response.data.data });
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: MODEL_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: MODEL_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const brandOptionsListRequest = () => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: BRAND_OPTION_LIST_REQUEST });

    axios
      .get(SERVER_URL + `/admin/watch/brand/list?page_num=0&page_size=99999999999`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let brandList = response.data.data.brands;
          let optionList = []
          brandList.forEach((ele)=>{
            optionList.push({
              id: ele.id,
              label: ele.name,
              value: ele.name 
            })
          })
          dispatch({ type: BRAND_OPTION_LIST_SUCCESS, payload: optionList });
         
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: BRAND_OPTION_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: BRAND_OPTION_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const modelListByBrandRequest = (brandId) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: MODEL_LIST_BY_BRAND_REQUEST });

    axios
      .get(SERVER_URL + `/admin/watch/model/list_by_brand?brandId=${brandId}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          let modeles = response.data.data;
          let optionList = []
          modeles.forEach((ele)=>{
            optionList.push({
              id: ele.id,
              label: ele.name,
              value: ele.name 
            })
          })
          dispatch({ type: MODEL_LIST_BY_BRAND_SUCCESS, payload: optionList });
         
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: MODEL_LIST_BY_BRAND_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: MODEL_LIST_BY_BRAND_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const newWatchRequest = (values) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: NEW_WATCH_REQUEST });

    axios
      .post(SERVER_URL + `/admin/watch/new`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: NEW_WATCH_SUCCESS});
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: NEW_WATCH_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: NEW_WATCH_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const watchListRequest = (pageNum, pageSize, keyword) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: WATCH_LIST_REQUEST });

    axios
      .get(SERVER_URL + `/admin/watch/list?page_num=${pageNum}&page_size=${pageSize}&keyword=${keyword}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: WATCH_LIST_SUCCESS, payload: response.data.data });
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: WATCH_LIST_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: WATCH_LIST_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const editWatchRequest = (values, id) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: EDIT_WATCH_REQUEST });

    axios
      .post(SERVER_URL + `/admin/watch/edit/${id}`, values, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: EDIT_WATCH_SUCCESS});
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: EDIT_WATCH_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: EDIT_WATCH_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const deleteWatchRequest = (id) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: DELETE_WATCH_REQUEST });

    axios
      .post(SERVER_URL + `/admin/watch/delete/${id}`, {}, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: DELETE_WATCH_SUCCESS});
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: DELETE_WATCH_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: DELETE_WATCH_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};

export const watchDetailsRequest = (id) => async (dispatch, getState) => {

  const {
    userLogin: { userInfo },
  } = getState();
  
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
     
    },
  };
  try {
    dispatch({ type: WATCH_DETAILS_REQUEST });

    axios
      .get(SERVER_URL + `/admin/watch/details/${id}`, config)
      .then(async (response) => authMiddleware(response, dispatch))
      .then(async (response) => {
        if (response.data.success) {
          dispatch({ type: WATCH_DETAILS_SUCCESS, payload: response.data.data });
        }else{
          throw response.data.message;
        }
    
      })
      .catch((err) => {
        dispatch({
          type: WATCH_DETAILS_FAIL,
          payload: err,
        });
      });
  } catch (error) {
    dispatch({
      type: WATCH_DETAILS_FAIL,
      payload: "Connection error, please try again later",
    });
  }
};