import React, { useEffect, useState } from "react";
import SingleImageUploader from "../../../components/SingleImageUploader";
import { Button } from "../../../components/buttons";
import {
  FieldLabel,
  SelectField,
  TextField
} from "../../../components/inputs";

export default function AdForm({ type, initialValues, onSave }) {
  const positions = [
    { id: 1, label: "Product Details Page", value: "Product Details Page" },
    { id: 2, label: "Product List Page", value: "Product List Page" },
    { id: 3, label: "User Wishlist Page", value: "User Wishlist Page" },
  ];

  const [formState, setFormState] = useState({});

  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["image"]: file.uri });
    }
  };

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  // // // // // //
  useEffect(() => {
    if (type === "edit") {
      setFormState({
        type: positions.filter(
          (x) => x.value === initialValues.type
        )[0],
        image: initialValues.image,
        uri: initialValues.uri,
      });
    } else {
      setFormState(initialValues);
    }
  }, [type, initialValues]);

  // // // // // //

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSave(formState);
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.type) {
      errors.type = "Ad position is required.";
    }
    if (!values.image) {
      errors.image = "Ad image is required.";
    }
    if (!values.uri) {
      errors.uri = "Ad link url is required.";
    }

    return errors;
  };

  return (
    <div className="w-full">
      <div className="w-1/2">
        <SelectField
          label="Position"
          placeHolder="Select position"
          selectOptions={positions}
          value={formState.type}
          extractKey=""
          onChange={(type) =>
            handleChange({
              target: { value: type, name: "type" },
            })
          }
          error={formErrors.type}
        />

        <div className="w-full">
          <FieldLabel label="Ad Image" />
          <p className="text-sm text-gray">Recommand image size: 1248px * 340 px</p>

          <div className="w-full h-[360px]">
            <SingleImageUploader
              onUpload={getUploadedImages}
              imageError={formErrors.image}
              uri={formState.image}
              folderName="ads"
              compress={false}
            />
          </div>
        </div>

        <TextField
          type="text"
          label="Ad Link Url"
          name="uri"
          value={formState.uri}
          onChange={(uri) =>
            handleChange({
              target: { value: uri, name: "uri" },
            })
          }
          error={formErrors.uri}
        />

        <div className="mt-8 w-full">
          <Button
            name={type === "new" ? "Save" : "Update"}
            action={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
