import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import menuStyle from "../../style/menuStyle.module.css";
import { AiOutlineUser, AiOutlineLogout } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { userlogout } from "../../redux/actions/userActions";

function Navbar() {
  const isdropdownOpen = false;
  const dispatch = useDispatch();

  const { userInfo, success } = useSelector((state) => state.userLogin);

  const logoutRequest = (e) => {
    e.preventDefault();
    dispatch(userlogout());
  };

  // let navigate = useNavigate();

  return (
    <div className={menuStyle.dropDownMenu}>
      <div
        className={
          isdropdownOpen
            ? `${menuStyle.dropdownItem} ${menuStyle.open}`
            : `${menuStyle.dropdownItem}`
        }
      >
        {userInfo ? (
          <>
            <AiOutlineUser className={menuStyle.accountIcon} />
            <span className="ml-2">Hi,{userInfo.data.user_name}</span>
          </>
        ) : (
          <></>
        )}

        <div className={menuStyle.dropdownContent}>
          <button className={menuStyle.logout} onClick={logoutRequest}>
            <AiOutlineLogout />

            <span style={{ marginLeft: "4px" }}>Log out</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
