import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { GrSecure } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { convToLocalDate, convtPrice } from "../../api/functions";
import ScreenTitle from "../../components/ScreenTitle";
import {
  ErrorMessage,
  Loader,
  OrderStatusTag,
  UserAvatar,
} from "../../components/smallComponents";
import { orderDetailsRequest } from "../../redux/actions/orderActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import {
  ActionWithFileButton,
  ContactButton,
  OrderActionButton,
  ShipOrderButton,
} from "./orderActionButtons";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;



export default function OrderDetails() {
  const { id } = useParams();
  useDocumentTitle("Order Details");
  const dispatch = useDispatch();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const { loading, details, success, error } = useSelector(
    (state) => state.orderDetails
  );

  const [saveStatus, setSaveStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [courierInfo, setCourierInfo] = useState("");

  useEffect(() => {
    dispatch(orderDetailsRequest(id));
    setSaveStatus({
      loading: false,
      success: false,
      error: "",
    });
  }, [dispatch, saveStatus.success]);

  console.log(details);


  useEffect(() => {
    if (success) {
      if (
        details.actionRecords.filter(
          (x) => x.action === "SHIPPED_PRODUCT_TO_BUYER"
        ).length
      ) {
        setCourierInfo(
          JSON.parse(
            details.actionRecords.filter(
              (x) => x.action === "SHIPPED_PRODUCT_TO_BUYER"
            )[0].action_info
          )
        );
      }
    } else {
      setCourierInfo("");
    }
  }, [success]);

  const successActionDone = () => {
    dispatch(orderDetailsRequest(id));
  };

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/orders">
            Orders
          </NavLink>
          <NavLink underline="hover" color="inherit">
            Details
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Orders Details" />

        {loading ? (
          <Loader mess="Requesting details..." />
        ) : success ? (
          <div className="w-full">
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 lg:justify-between">
              <div className="w-full flex items-center p-4">
                <span>Order Number: {details.orderNumber}</span>

                <OrderStatusTag currState={details.currState} />
              </div>

              <div className="w-full flex justify-between p-4 ">
                <div className="w-1/2 flex ">
                  <div className="w-20 h-20">
                    <img
                      alt={
                        details.imageUris.length
                          ? details.imageUris[0]
                          : "default-watch"
                      }
                      src={
                        details.imageUris.length
                          ? S3_DOMAIN + details.imageUris[0]
                          : "/default-car.svg"
                      }
                      className="w-full h-full object-cover"
                      style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                      }}
                    />
                  </div>

                  <div className="ml-6">
                    <div>
                      <span className="mr-2">{details.brandName}</span>
                      <span>{details.modelName}</span>
                    </div>

                    <p>{details.referenceNumber}</p>
                  </div>
                </div>

                <div className="w-1/2 flex flex-col">
                  <div className="flex items-center">
                    <p>Product Price: ${convtPrice(details.price)}</p>
                  </div>

                  <div className="flex items-center mt-4">
                    <p>Shipping Fee: ${convtPrice(details.shippingFee)}</p>
                  </div>

                  <div className="flex items-center mt-4 text-lg font-semibold py-2">
                    Order Total: $
                    {convtPrice(
                      Number(details.shippingFee) + Number(details.price)
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full p-4">
                {details.actions.includes("SEND_SHIPPING_LABEL_TO_SELLER") ? (
                  <div className="mt-4">
                    <ActionWithFileButton
                      actionName="SEND_SHIPPING_LABEL_TO_SELLER"
                      modalTitle={"Send Shipping Label"}
                      actionButtonName="Send Shipping Label"
                      successActionText={" Shipping label files sent successfully."}
                      orderId={details.id}
                      userType={"platform"}
                      modalButtonText="Send Shipping Label"
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {details.actions.includes("PRODUCT_RECEIVED") ? (
                  <div className="mt-4">
                    <OrderActionButton
                      actionName="PRODUCT_RECEIVED"
                      actionButtonName="Confirm Received Product"
                      modalTitle="Confirm Received Product"
                      modalText="Are you sure you have received the product?"
                      successActionText="Product received!"
                      modalButtonText="Yes, Proceed"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}
                {details.currState === "PRODUCT_RECEIVED_BY_PLATFORM" && (
                  <div className="w-full flex">
                    {details.actions.includes("PRODUCT_INSPECTION_PASS") ? (
                      <div className="w-1/2 mt-4">
                        <ActionWithFileButton
                          actionName="PRODUCT_INSPECTION_PASS"
                          actionButtonName="Passing the Inspection"
                          modalTitle="Passing the Inspection"
                          //modalText="Are you sure you have received the product?"
                          successActionText="Product successfully passed inspection."
                          modalButtonText="Submit"
                          orderId={details.id}
                          userType={"platform"}
                          successActionDone={successActionDone}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                    {details.actions.includes("PRODUCT_INSPECTION_FAIL") ? (
                      <div className="w-1/2 mt-4 ml-4">
                        <ActionWithFileButton
                          actionName="PRODUCT_INSPECTION_FAIL"
                          actionButtonName="Failing the Inspection"
                          modalTitle="Failing the Inspection"
                          //modalText="Are you sure you have received the product?"
                          successActionText="Product did not pass inspection."
                          modalButtonText="Submit"
                          orderId={details.id}
                          userType={"platform"}
                          successActionDone={successActionDone}
                        />
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                )}
                {details.actions.includes("SHIPPED_PRODUCT_TO_BUYER") ? (
                  <div className="mt-4">
                    <ShipOrderButton
                      actionName="SHIPPED_PRODUCT_TO_BUYER"
                      actionButtonName="Ship Order"
                      modalTitle="Ship Order"
                      modalText="Please enter order tracking details"
                      successActionText="Shipping details updated successfully."
                      modalButtonText="Submit"
                      orderId={details.id}
                      userType={"platform"}
                      successActionDone={successActionDone}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* Authenticae Order */}
            {details.type !== "DEFAULT" && (
              <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4 ">
                <div>
                  <div className="flex items-center">
                    <GrSecure className="w-8 h-8 text-brandYellow" />
                    <span className="text-xl ml-2">Authenticate Guarantee</span>
                  </div>

                  <div className="pl-10 w-full">
             
                    <p className="">
                        Service Fee: ${convtPrice(details.serviceFee)}
                      </p>
                      {details.type === "BUYERINSPECTION" ? <p className="mt-1 text-sm text-gray">(Buyer pay service fee)</p> :  <p className="mt-1 text-sm text-gray">(Seller pay service fee)</p>}
                      {details.serviceFeePaid ? <p className="mt-1">Service Fee Payment Status: Paid</p> : <></>}
                    {/* seller or buyer pay service fee */}
                  </div>
                </div>
              </div>
            )}

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4">
              <h3>Seller</h3>

              <div className="flex justify-between flex-col lg:flex-row">
                <div className="flex items-center mt-4">
                  <UserAvatar name={details.sellerName} />{" "}
                  <span className="ml-4">{details.sellerName}</span>
                </div>

                <div className="w-fit text-sm text-darkGray mt-4">
                  <ContactButton
                    recipientId={details.sellerId}
                    productId={details.productId}
                    buttonName={"Contact Seller"}
                  />
                </div>
              </div>
            </div>

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6  p-4">
              <h3>Buyer</h3>

              <div className="flex justify-between flex-col lg:flex-row">
                <div className="flex items-center mt-4">
                  <UserAvatar name={details.buyerName} />{" "}
                  <span className="ml-4">{details.buyerName}</span>
                </div>

                <div className="w-fit text-sm text-darkGray mt-4">
                  <ContactButton
                    recipientId={details.buyerId}
                    productId={details.productId}
                    buttonName={"Contact Buyer"}
                  />
                </div>
              </div>
            </div>

            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 p-4">
              <h3>Shipping Address</h3>

              {courierInfo && (
                <div className="text-sm text-darkGray mt-4">
                  <p>Courier: {courierInfo.courier.label}</p>
                  <p>Tracking Number: {courierInfo.trackingNumber}</p>
                </div>
              )}
              <div className="text-sm text-darkGray mt-4">
                <p className="text-sm ">
                  {details.shippingAddr.firstName}{" "}
                  {details.shippingAddr.lastName}, {details.shippingAddr.phone}
                </p>

                <p className="text-sm">
                  {details.shippingAddr.streetLine1}, {details.shippingAddr.city}
                  , {details.shippingAddr.region},{" "}
                  {details.shippingAddr.country
                    ? details.shippingAddr.country.label
                    : ""}
                  , {details.shippingAddr.postalCode}
                </p>
              </div>
            </div>
            {/* buyer info */}
            <div className="w-full h-full border-b border-solid border-borderGray mt-6 lg:justify-between p-4">
              <h3>Billing Address</h3>
              <div className="text-sm text-darkGray mt-4">
                <p className="text-sm ">
                  {details.billingAddr.firstName} {details.billingAddr.lastName}
                  , {details.billingAddr.phone}
                </p>

                <p className="text-sm">
                  {details.billingAddr.streetLine1}, {details.billingAddr.city},{" "}
                  {details.billingAddr.region},{" "}
                  {details.billingAddr.country
                    ? details.billingAddr.country.label
                    : ""}
                  , {details.billingAddr.postalCode}
                </p>
              </div>
            </div>

            {/* order status change details */}
            <div className="w-full h-full border-b border-borderGray mt-6 lg:justify-between p-4">
              <h3>Order Logs</h3>
              <div className="text-sm text-darkGray mt-4">
                <div>
                  <span className="text-sm">
                    {convToLocalDate(details.atime)}
                  </span>
                  <span className="text-sm ml-4">Order created</span>
                </div>

                {details.actionRecords.length ? (
                  details.actionRecords.map((item, index) => (
                    <div key={index} className="mt-2">
                      <span className="text-sm">
                        {convToLocalDate(item.atime)}
                      </span>
                      <span className="text-sm ml-4"> {item.actionName}</span>
                      {item.userType && (
                        <span className="text-sm">
                          {" by " + item.userType}
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* order actions */}

            <div className="w-full flex flex-col justify-start p-4">
              {details.actions.includes("CANCEL_ORDER") ? (
                <div className="mt-4">
                  <OrderActionButton
                    actionName="CANCEL_ORDER"
                    actionButtonName="Cancel Order"
                    modalTitle="Cancel Order"
                    modalText="Are you sure you want to cancel the order?"
                    successActionText="Order has been cancelled!"
                    modalButtonText="Yes, Please Cancel The Order"
                    orderId={details.id}
                    userType={"platform"}
                    successActionDone={successActionDone}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : error ? (
          <ErrorMessage mess={error} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
