export const WATCH_CONSTANT_OPTIONS_REQUEST = "WATCH_CONSTANT_OPTIONS_REQUEST";
export const WATCH_CONSTANT_OPTIONS_SUCCESS = "WATCH_CONSTANT_OPTIONS_SUCCESS";
export const WATCH_CONSTANT_OPTIONS_FAIL = "WATCH_CONSTANT_OPTIONS_FAIL";

export const WATCH_LIST_REQUEST = "WATCH_LIST_REQUEST";
export const WATCH_LIST_SUCCESS = "WATCH_LIST_SUCCESS";
export const WATCH_LIST_FAIL = "WATCH_LIST_FAIL";

export const WATCH_DETAILS_REQUEST = "WATCH_DETAILS_REQUEST";
export const WATCH_DETAILS_SUCCESS = "WATCH_DETAILS_SUCCESS";
export const WATCH_DETAILS_FAIL = "WATCH_DETAILS_FAIL";

export const NEW_WATCH_REQUEST = "NEW_WATCH_REQUEST";
export const NEW_WATCH_SUCCESS = "NEW_WATCH_SUCCESS";
export const NEW_WATCH_FAIL = "NEW_WATCH_FAIL";

export const EDIT_WATCH_REQUEST = "EDIT_WATCH_REQUEST";
export const EDIT_WATCH_SUCCESS = "EDIT_WATCH_SUCCESS";
export const EDIT_WATCH_FAIL = "EDIT_WATCH_FAIL";

export const DELETE_WATCH_REQUEST = "DELETE_WATCH_REQUEST";
export const DELETE_WATCH_SUCCESS = "DELETE_WATCH_SUCCESS";
export const DELETE_WATCH_FAIL = "DELETE_WATCH_FAIL";

export const NEW_BRAND_REQUEST = "NEW_BRAND_REQUEST";
export const NEW_BRAND_SUCCESS = "NEW_BRAND_SUCCESS";
export const NEW_BRAND_FAIL = "NEW_BRAND_FAIL";

export const NEW_MODEL_REQUEST = "NEW_MODEL_REQUEST";
export const NEW_MODEL_SUCCESS = "NEW_MODEL_SUCCESS";
export const NEW_MODEL_FAIL = "NEW_MODEL_FAIL";

export const BRAND_LIST_REQUEST = "BRAND_LIST_REQUEST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAIL = "BRAND_LIST_FAIL";

export const BRAND_OPTION_LIST_REQUEST = "BRAND_OPTION_LIST_REQUEST";
export const BRAND_OPTION_LIST_SUCCESS = "BRAND_OPTION_LIST_SUCCESS";
export const BRAND_OPTION_LIST_FAIL = "BRAND_OPTION_LIST_FAIL";

export const MODEL_LIST_REQUEST = "MODEL_LIST_REQUEST";
export const MODEL_LIST_SUCCESS = "MODEL_LIST_SUCCESS";
export const MODEL_LIST_FAIL = "MODEL_LIST_FAIL";

export const MODEL_LIST_BY_BRAND_REQUEST = "MODEL_LIST_BY_BRAND_REQUEST";
export const MODEL_LIST_BY_BRAND_SUCCESS = "MODEL_LIST_BY_BRAND_SUCCESS";
export const MODEL_LIST_BY_BRAND_FAIL = "MODEL_LIST_BY_BRAND_FAIL";