// get package list
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const newBlogRequest = async (values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/blog/create`, values, config);
    return response;
  } catch (error) {
    throw error;
  }

};

export const blogListRequest = async (config) => {

  try {
    const response = await axios.get(SERVER_URL + `/admin/blog/list`, config);
    return response;
  } catch (error) {
    throw error;
  }

};

export const updateBlogRequest = async (id, values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/blog/update/${id}`, values, config);
    return response;
  } catch (error) {
    throw error;
  }

};

export const blogDetailsRequest = async (blogId, config) => {

  try {
    const response = await axios.get(SERVER_URL + `/admin/blog/details-by-id/${blogId}`, config);
    return response;
  } catch (error) {
    throw error;
  }

};