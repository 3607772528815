const K_YEARS = [];

const getYears = () => {
  if (! K_YEARS.length) {
    for (let i = 2024; i >= 1930; i -= 1) {
      K_YEARS.push({id: i, name: i});
    }
  }
  return K_YEARS;
}

export {getYears};