import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";

import AddressCollector from "../../components/AddressCollector";
import ScreenTitle from "../../components/ScreenTitle";
import SingleImageUploader from "../../components/SingleImageUploader";
import { Button } from "../../components/buttons";
import { SimpleDialog } from "../../components/dialogs";
import {
  FieldLabel,
  SelectField,
  TextAreaField,
  TextField,
} from "../../components/inputs";
import { ErrorMessage, H3, Loader } from "../../components/smallComponents";
import {
  newUserRequest,
  updateUserRequest,
  userDetailsRequest,
} from "../../redux/actions/userActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import CompanyInfoCollector from "./CompanyInfoCollector";
import { K_COUNTRIES } from "../../constantData/K_COUNTRIES";

export default function EditUser() {
  useDocumentTitle("Edit User");
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const {
    loading: detailsLoading,
    details,
    success: detailsSuccess,
    error: detailsError,
  } = useSelector((state) => state.userDetails);

  const { loading, success, error } = useSelector((state) => state.editUser);

  const [address, setAddress] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    streetLine1: "",
    city: "",
    region: "",
    country: "",
    postalCode: "",
  });

  const [companyInfo, setCompanyInfo] = useState({
    companyName: "",
    einNumber: "",
    companyWeb: "",
    description: "",
  });

  const [formState, setFormState] = useState({
    email: "",
    userType: "",
    avatar: "",
  });

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };
  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["avatar"]: file.uri });
    }
  };

  useEffect(() => {
    dispatch(userDetailsRequest(userId));
  }, [dispatch]);

  useEffect(() => {
    if (detailsSuccess) {
      setAddress({
        firstName: details.firstName,
        lastName: details.lastName,
        phone: details.phone,
        streetLine1: details.streetLine1,
        city: details.city,
        region: details.region,
        country: details.country ? K_COUNTRIES.filter(
          (x) => x.value === details.country
        )[0] : "",
        postalCode: details.postalCode,
      });
      setCompanyInfo({
        companyName: details.companyName,
        einNumber: details.einNumber,
        companyWeb: details.companyWeb,
        description: details.description,
      });

      setFormState({
        email: details.email,
        userType: details.role,
        avatar: details.avatar,
      });
    }
  }, [detailsSuccess]);

  // // // // // //

  const validate = (values) => {
    const errors = {};
    if (!values.accountInfo.email) {
      errors.email = "User email is required.";
    }
    if (!values.accountInfo.userType) {
      errors.userType = "User type is required.";
    }
    if (!values.companyInfo.companyName) {
      errors.companyName = "Company name is required.";
    }
    if (!values.contact.firstName) {
      errors.firstName = "First name is required.";
    }
    if (!values.contact.lastName) {
      errors.lastName = "Last name is required.";
    }
    if (!values.contact.phone) {
      errors.phone = "Phone is required.";
    }
    if (!values.contact.streetLine1) {
      errors.streetLine1 = "street line is required.";
    }
    if (!values.contact.country) {
      errors.country = "Country is required.";
    }
    if (!values.contact.postalCode) {
      errors.postalCode = "Postal code is required.";
    }
    if (!values.contact.city) {
      errors.city = "City is required.";
    }
    return errors;
  };

  //  dialog
  const [dialog, setDialog] = useState(false);

  const checkValidation = () => {
    let checkValues = {
      contact: address,
      companyInfo: companyInfo,
      accountInfo: formState,
    };

    const hasErrors = validate(checkValues);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      setDialog(true);
      let loginValues = {
        name: companyInfo.companyName,
        email: formState.email,
      };

      let contactInfo = { ...address };

      if (typeof contactInfo.country === "object") {
        contactInfo.countryName = contactInfo.country.value;
      }

      let registerValues = {
        loginDetails: loginValues,
        contact: contactInfo,
        companyInfo: companyInfo,
        frontImage: "",
        backImage: "",
        avatar: formState.avatar,
      };
      dispatch(updateUserRequest(userId, registerValues));
    } else {
      setFormErrors(hasErrors);
    }
  };

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate(`/users/details/${userId}`, { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit User"
        successMessage="User updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/users">
            Users
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/users/edit/${userId}`}
            aria-current="page"
          >
            Edit User
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Edit User" />
        {detailsLoading ? (
          <Loader mess="Requesting user details..." />
        ) : detailsSuccess ? (
          <div className="w-full flex flex-row mt-6">
            <div className="w-2/3">
              <div className="">
                <H3 title="Account Information" />
              </div>
              <FieldLabel label="User Type" />
              <div className="h-12 bg-lightGray flex items-center px-4 mt-2">
                <p>{formState.userType}</p>
              </div>

              <TextField
                type="text"
                label="Email*"
                name="email"
                autofocus={true}
                value={formState.email}
                onChange={(email) =>
                  handleChange({
                    target: { value: email, name: "email" },
                  })
                }
                error={formErrors.email}
                readOnly={loading ? true : false}
              />

              <div className="mt-6">
                <H3 title="Company Information" />
              </div>

              <CompanyInfoCollector
                formState={companyInfo}
                errors={formErrors}
                onChange={(details) => setCompanyInfo(details)}
              />
              <div className="mt-6">
                <H3 title="Contact Information" />
              </div>

              <AddressCollector
                formState={address}
                errors={formErrors}
                onChange={(address) => setAddress(address)}
              />
              <div className="my-4">
                <Button name="Save" action={checkValidation} />
              </div>
            </div>
            <div className="w-1/3 px-6">
              <h2 className="mb-2">User Logo</h2>
              <SingleImageUploader
                onUpload={getUploadedImages}
                imageError={formErrors.avatar}
                uri={formState.avatar}
                folderName="user"
                compress={false}
              />
            </div>
          </div>
        ) : detailsError ? (
          <ErrorMessage mess={detailsError} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
