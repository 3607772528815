
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

export const getVehicleMakeOptionsRequest = async (config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/cardata/makes`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};


export const getVehicleModelOptionsRequest = async (makeId, year, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/cardata/models?makeId=${makeId}&year=${year}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getVehicleGenerationsRequest = async (makeId, year, modelId, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/cardata/generations?makeId=${makeId}&year=${year}&modelId=${modelId}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};


export const getVehicleTrimsRequest = async (generationId, year, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/cardata/trims?genId=${generationId}&year=${year}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};


// export const addNewProductRequest = async (listingId, values, config, dispatch) => {

//   try {
//     let route = "";
//     if(listingId){
//       route = SERVER_URL + `/listing/car/edit/${listingId}`
//     }else{
//       route = SERVER_URL + `/listing/car/create`
//     }
//     const response = await axios.post(
//       route,
//       values,
//       config
//     );
//     authMiddleware(response, dispatch);
//     return response;
//   } catch (error) {
//     throw error.toString();
//   }
// };


export const searchWatchRequest = async (keyword, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/web/sell/watch/search?keyword=${keyword}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getWatchDetailsRequest = async (watchId, config, dispatch) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/product/watch/details/${watchId}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const getDealerListRequest = async (watchId, config, dispatch) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/product/dealer-list`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const updateProductRequest = async ( listingId, values, config) => {
  try {
  
    const response = await axios.post(
      SERVER_URL + `/admin/listing/car/edit/${listingId}`,
      values,
      config
    );
    return response;
  } catch (error) {
    throw error.toString();
  }
};

export const getProductEditDetails = async (listingId, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/listing/car/detail/${listingId}`, config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

export const deleteProductListing = async (productId, config) => {
  try {
    const response = await axios.get(SERVER_URL + `/admin/product/delete-listing/${productId}`, config);
    return response;
  } catch (error) {
    throw error;
  }
};



