import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import useDocumentTitle from "../../useDocumentTitle";
import ScreenTitle from "../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import { SimpleDialog } from "../../components/dialogs";
import WatchDetailsForm from "./WatchDetailsForm";
import { useDispatch, useSelector } from "react-redux";
import {
  brandOptionsListRequest,
  newWatchRequest,
} from "../../redux/actions/watchActions";
import { ErrorMessage, Loader } from "../../components/smallComponents";

export default function NewWatch() {
  useDocumentTitle("Add New Watch");
  const { slug } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //const loading = false;
  const { loading, success, error } = useSelector((state) => state.newWatch);

  const {
    loading: brandsLoading,
    success: brandsSuccess,
    error: brandsError,
  } = useSelector((state) => state.brandOptionList);

  useEffect(() => {
    dispatch(brandOptionsListRequest());
  }, [dispatch]);

  const initialvalues = {
    brandId: "",
    modelId: "",
    brandName: "",
    modelName: "",
    referenceNumber: "",
    watchTypeId: "",
    movementId: "",
    caseDiameterWidth: "",
    caseDiameterHeight: "",
    genderId: "",
    caliMovement: "",
    baseCaliber: "",
    powerReserve: "",
    jewelNumbers: "",
    frequency: "",
    caseMaterialId: "",
    bezelMaterialId: "",
    thickness: "",
    glassId: "",
    waterResistanceId: "",
    dialColorId: "",
    dialNumeralsId: "",
    braceletMaterialId: "",
    braceletColorId: "",
    claspId: "",
    claspMaterialId: "",
    otherAttributeIds:[],
    watchFunctionsIds: [],
    imageUri: "",
  };


  //  dialog
  const saveNewWatch = (watchValues) => {
    let submittedValues = {
      brand_id: watchValues.brandId,
      model_id: watchValues.modelId,
      reference_number: watchValues.referenceNumber,
      watch_type_id: watchValues.watchTypeId,

      movement_id: watchValues.movementId,
      case_diameter_w: watchValues.caseDiameterWidth,
      case_diameter_h: watchValues.caseDiameterHeight,
      gender_id: watchValues.genderId,
      watch_image_uri: watchValues.imageUri,

      cali_movement: watchValues.caliMovement,
      base_caliber: watchValues.baseCaliber,
      power_reserve: watchValues.powerReserve,
      jewel_numbers: watchValues.jewelNumbers,
      frequency: watchValues.frequency,
      
      case_material_id: watchValues.caseMaterialId,
      bezel_material_id: watchValues.bezelMaterialId,
      thickness: watchValues.thickness,
      glass_id: watchValues.glassId,
      water_resistance_id: watchValues.waterResistanceId,

      dial_color_id: watchValues.dialColorId,
      dial_numerals_id: watchValues.dialNumeralsId,

      bracelet_material_id: watchValues.braceletMaterialId,
      bracelet_color_id: watchValues.braceletColorId,
      clasp_id: watchValues.claspId,
      clasp_material_id: watchValues.claspMaterialId,

      other_attribute_ids: watchValues.otherAttributeIds,

      watch_functions_ids: watchValues.watchFunctionsIds,
    };

    setDialog(true);

    dispatch(newWatchRequest(submittedValues));
  };
  const [dialog, setDialog] = useState(false);



  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/watches", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Add New Watch"
        successMessage="New watch details added successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/watches">
            Watches
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/watch/new/`}
            aria-current="page"
          >
            New Watch
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Add New Watch" />

        {brandsLoading ? (
          <Loader mess="Requesting.." />
        ) : brandsSuccess ? (
          <WatchDetailsForm
            type="new"
            onSave={saveNewWatch}
            watchDetailsInfo={initialvalues}
          />
        ) : brandsError ? (
          <ErrorMessage mess={brandsError} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
