import React, { useState, useEffect, useRef } from "react";
import Select, { components } from "react-select";
import {
  MdOutlineClose,
  MdKeyboardArrowDown,
  MdOutlineSearch,
  MdOutlineCalendarToday,
} from "react-icons/md";
import {
  AiOutlineClose,
  AiOutlineDelete,
  AiOutlineEyeInvisible,
  AiOutlineEye,
  AiOutlineCheckCircle,
} from "react-icons/ai";
import commonStyle from "../style/commonStyle.module.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../style/datepickerStyle.css";
import { addDays, subDays } from "date-fns";
import { MdClear } from "react-icons/md";
import { EngineIcon, DriveIcon, FuelTypeIcon, BatteryIcon, TransmissionIcon, CarBodayTag } from "./smallComponents"

/*
UI guideline

font size:
PC: body: 16px-18px(1rem), heading: 1.96倍大于body size, 32px/ 2rem
mobile: 14-16px,, heading: 1.3倍大于body size, 21px/ 1.3rem

margin: 8px的倍数
padding: 8px的倍数

x 方向的padding是y方向的3倍

button text 大小和body一致
*/

function FieldLabel({ label }) {
  return <p className="w-full mt-6">{label}</p>;
}

function ReadOnlyField({ label, readOnlyName }) {
  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="w-full mt-2 h-12 bg-lightGray flex items-center px-2">
        {readOnlyName}
      </div>
    </div>
  );
}

function TextField({
  type,
  label,
  name,
  value,
  placeholder,
  onChange,
  autoFocus = false,
  readOnly,
  error,
  unit = "",
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative w-full">
      <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <input
          type={type}
          name={name}
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          autoFocus={autoFocus}
          readOnly={readOnly}
          className="w-full h-12 pl-4 py-2 rounded border-borderGray border-2 focus:outline-black focus:border-black active:outline-black"
        ></input>
        {unit ? (
          <span className="absolute right-0 w-10 flex items-center justify-center">
            {unit}
          </span>
        ) : (
          <></>
        )}
      </div>
      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
    </div>
  );
}

function PasswordField({
  name,
  value,
  label,
  autoFocus,
  placeholder,
  onChange,
  error,
  showHints = true,
  readOnly,
}) {
  const [isFocused, setIsFocused] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const [hint1, setHint1] = useState(false);
  // const [hint2, setHint2] = useState(false);
  // const [hint3, setHint3] = useState(false);
  // const [hint4, setHint4] = useState(false);
  // const [hint5, setHint5] = useState(false);

  // handle password field changes

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    // const lowerCaseCount = (value.match(/[a-z]/g) || []).length;
    // const upperCaseCount = (value.match(/[A-Z]/g) || []).length;
    // const numberCount = (value.match(/\d/g) || []).length;
    // const specialCount = (value.match(/[~^@#$!%*?&]/g) || []).length;
    setHint1(value.length > 7 ? true : false);

    // setHint2(
    //   numberCount > 0 ? componentsStyle.hintPassed : componentsStyle.hintError
    // );
    // setHint3(
    //   upperCaseCount > 0
    //     ? componentsStyle.hintPassed
    //     : componentsStyle.hintError
    // );
    // setHint4(
    //   lowerCaseCount > 0
    //     ? componentsStyle.hintPassed
    //     : componentsStyle.hintError
    // );

    // setHint5(
    //   specialCount > 0 ? componentsStyle.hintPassed : componentsStyle.hintError
    // );

    onChange(value);
  };

  const handleShowPwdChange = (e) => {
    e.preventDefault();
    setShowPassword(!showPassword);
  };
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="relative w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <input
          type="password"
          name={name}
          value={value}
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={placeholder}
          autoFocus={autoFocus}
          readOnly={readOnly}
          className="w-full h-12 pl-4 py-2 rounded border-borderGray border-2 focus:outline-black focus:border-black active:outline-black"
        ></input>
        <button
          className="absolute right-0 w-10 flex items-center justify-center"
          onClick={handleShowPwdChange}
        >
          {showPassword ? (
            <AiOutlineEye className="w-6 h-6 text-gray-500" />
          ) : (
            <AiOutlineEyeInvisible className="w-6 h-6 text-gray-500" />
          )}
        </button>
      </div>

      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
      {showHints && value.length > 0 && isFocused ? (
        <div className="absolute top-20 w-full shadow-md py-2 bg-white">
          <div
            className={`w-full flex items-center ${
              hint1 ? "text-green-500" : "text-black"
            }`}
          >
            <AiOutlineCheckCircle />
            <p style={{ marginLeft: "2px" }}>At least 8 characters</p>
          </div>
          {/* <div className={hint2}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 number</p>
        </div>
        <div className={hint3}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 uppercase letter</p>
        </div>
        <div className={hint4}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 lowercase letter</p>
        </div>
        <div className={hint5}>
          <AiOutlineCheckCircle />
          <p style={{ marginLeft: "2px" }}>Contains 1 special character</p>
        </div> */}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function TextAreaField({
  type,
  label,
  name,
  value,
  placeholder,
  onChange,
  rows,
  error,
  unit = "",
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="relative flex items-center w-full mt-2">
        <textarea
          type={type}
          name={name}
          value={value ? value : ""}
          onChange={handleChange}
          placeholder={placeholder}
          className="w-full min-h-12 pl-4 py-2 rounded border-borderGray border-2 focus:outline-black focus:border-black active:outline-black"
          rows={rows}
        ></textarea>
        {unit ? (
          <span className="absolute right-0 w-10 flex items-center justify-center">
            {unit}
          </span>
        ) : (
          <></>
        )}
      </div>
      {error ? <p className="w-full text-red-500 mt-2 ">{error}</p> : <></>}
    </div>
  );
}

const RatioField = ({ name, label, isOn, onChange }) => {
  return (
    <div className="w-full mt-6">
      <label
        className={`${commonStyle.checkboxContainer} ${commonStyle.labelSize}`}
      >
        {label}
        <input
          type="checkbox"
          name={name}
          checked={isOn}
          onChange={(e) => onChange(e.target.checked)}
        />
        <span className={commonStyle.checkmark}></span>
      </label>
    </div>
  );
};

function SelectField({
  value,
  label,
  selectOptions,
  onChange,
  error,
  extractKey,
  placeHolder,
  clearable = true,
  readOnly = false,
  readOnlyName = "",
}) {
  const handleSelect = (value) => {
    onChange(extractKey && value ? value[extractKey] : value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "black" : "#E5E7E7",
      borderWidth: "2px",
      outline: state.isFocused ? "black" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: state.isFocused ? "black" : "black",
      },
      innerHeight: "48px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 6px",
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      backgroundColor: state.isSelected
        ? "var(--light-gray-bc)"
        : "var(--white-color)",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--light-gray-bc)",
      },
    }),

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--main-color)",
    }),
  };

  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      {readOnly ? (
        <div className="w-full mt-2 h-12 bg-lightGray flex items-center px-2">
          {readOnlyName}
        </div>
      ) : (
        <div className="w-full mt-2">
          <Select
            value={
              extractKey
                ? selectOptions.filter((e) => e[extractKey] === value)
                : value
            }
            components={{ DropdownIndicator, ClearIndicator }}
            placeholder={placeHolder}
            onChange={(e) => handleSelect(e)}
            isClearable={clearable}
            options={selectOptions}
            styles={customStyles}
          />
        </div>
      )}

      <p className="w-full text-red-500 mt-2 ">{error}</p>
    </div>
  );
}

function MultiSelectField({
  value,
  label,
  selectOptions,
  onChange,
  error,
  placeHolder,
  clearable = true,
}) {
  const handleSelect = (value) => {
    onChange(value);
  };

  // set select dropdown icon
  const DropdownIndicator = (DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...DropdownIndicatorProps}>
        <MdKeyboardArrowDown />
      </components.DropdownIndicator>
    );
  };
  // set select clear icon
  const ClearIndicator = (ClearIndicatorProps) => {
    return (
      <components.DropdownIndicator {...ClearIndicatorProps}>
        <MdOutlineClose />
      </components.DropdownIndicator>
    );
  };

  const MultiValueRemove = (MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...MultiValueRemoveProps}>
        <MdOutlineClose />
      </components.MultiValueRemove>
    );
  };
  // set custom color
  const customStyles = {
    control: (base, state) => ({
      ...base,
      borderColor: state.isFocused ? "black" : "#E5E7E7",
      borderWidth: "2px",
      outline: state.isFocused ? "black" : "none",
      boxShadow: state.isFocused ? 0 : 0,
      "&:hover": {
        borderColor: state.isFocused ? "black" : "black",
      },
      innerHeight: "48px",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: "44px",
      padding: "0 6px",
    }),
    option: (base, state) => ({
      ...base,
      color: "black",
      backgroundColor: state.isSelected
        ? "var(--main-color)"
        : "var(--white-color)",
      padding: ".5rem 3rem .5rem .5rem",
      cursor: "pointer",
      "&:hover": {
        backgroundColor: "var(--table-hover)",
      },
    }),

    IndicatorsContainer: (base, state) => ({
      ...base,
      backgroundColor: "var(--main-color)",
    }),
  };

  return (
    <div className="w-full mt-6">
      <label className="w-full text-base text-black">{label}</label>
      <div className="w-full mt-2">
        <Select
          value={value}
          components={{ DropdownIndicator, ClearIndicator, MultiValueRemove }}
          placeholder={placeHolder}
          onChange={(e) => handleSelect(e)}
          isClearable={clearable}
          isMulti
          options={selectOptions}
          styles={customStyles}
        />
      </div>
      <p className="w-full text-red-500 mt-2 ">{error}</p>
    </div>
  );
}

function SearchField({ initialQuery, placeholder, onChange }) {
  const [query, setQuery] = useState(initialQuery);

  const handleChange = (value) => {
    setQuery(value);
    onChange(value);
  };

  return (
    <div className="relative w-full mt-6">
      <input
        type="text"
        className="w-full h-12 px-4 py-2 border border-borderGray rounded text-black text-base focus:outline-black focus:border-black active:outline-black"
        value={query}
        onChange={(e) => handleChange(e.target.value)}
        placeholder={placeholder}
        style={{ height: "48px" }}
      />
      <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
        <MdOutlineSearch className="h-5 w-5 text-gray" />
      </div>
    </div>
  );
}

function DateInput({
  label,
  error,
  disabledPreDate,
  minDay,
  value,
  onChange,
  maxDay,
}) {
  let customMaxdate = new Date();
  let customMindate = new Date();

  if (maxDay > 0) {
    customMaxdate = addDays(new Date(), maxDay);
  }

  if (minDay > 0) {
    customMindate = subDays(new Date(), minDay);
  }

  return (
    <div className="w-full mt-4 pr-2">
      <label className="w-full text-base text-black">{label}</label>

      <div className="w-full rounded border border-solid border-borderGray mt-2 pl-2">
        <DatePicker
          wrapperClassName="datepicker"
          prevMonthButtonDisabled={disabledPreDate}
          showPopperArrow={false}
          selected={value}
          onChange={(date) => onChange(date)}
          minDate={customMindate}
          maxDate={customMaxdate}
          placeholderText="Select a day"
          dateFormat="yyyy/MM/dd"
          disabledKeyboardNavigation
        />
      </div>
      {error && !value ? <p className="text-red-500">{error}</p> : <></>}
    </div>
  );
}

function PureTextField({
  type,
  name,
  value,
  placeholder,
  onChange,
  autoFocus = false,
  hasBorder = false,
  showSearchIcon = false,
  dollarIcon = false,
}) {
  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
  };

  return (
    <div className="relative flex items-center w-full text-black">
      {dollarIcon ? (
        <span className="absolute left-0 w-10 flex items-center justify-center">
          $
        </span>
      ) : (
        <></>
      )}
      <input
        type={type}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        autoFocus={autoFocus}
        className={`w-full h-12 ${
          dollarIcon ? "pl-8" : "pl-4"
        } py-2 rounded border-borderGray hover:border-black focus:outline-black focus:border-black active:outline-black ${
          hasBorder ? "border-2" : ""
        } `}
      ></input>
      {showSearchIcon ? (
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
          <MdOutlineSearch className="h-5 w-5 text-gray" />
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function TypeSelectFiled({
  name,
  value,
  label,
  options,
  onChange,
  noResultText,
  extractKey = "name",
  placeholder = "",
  autoFocus = false,
  readOnly = false,
  error = "",
  optional = false,
  clearable = false,
}) {
  const containerRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setKeyword(value);
    if (value) {
      let filtered = options.filter((x) =>
        x.name.toString().toLowerCase().includes(value.toString().toLowerCase())
      );
      setFilterOptions(filtered);
    } else {
      setFilterOptions(options);
    }
    setShowSearchResults(true);
  };

  const handleSelect = (selectedItem) => {
    if (extractKey === "id") {
      onChange(selectedItem.id.toString());
    } else {
      onChange(selectedItem.name.toString());
    }
    setShowSearchResults(false);
  };

  useEffect(() => {
    if (options && options.length) {
      setFilterOptions(options);
    }
  }, [options]);

  useEffect(() => {
    let stringValue = value ? value.toString() : "";
    if (stringValue) {
      if (extractKey === "id") {
        let exist = options.filter((x) => Number(x.id) === Number(value));
        if (exist.length) {
          setKeyword(exist[0].name);
        } else {
          setKeyword("");
        }
      } else {
        let exist = options.filter((x) => x.name === value);
        if (exist.length) {
          setKeyword(exist[0].name);
        } else {
          setKeyword("");
        }
      }
    } else {
      setKeyword("");
    }
  }, [value, options]);

  useEffect(() => {
    // Function to check if click is outside the component
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
      }
    };

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clickSelectIcon = (e) => {
    e.preventDefault();
    setShowSearchResults(!showSearchResults);
  };
  const clearSelect = (e) => {
    e.preventDefault();
    onChange("");
    setKeyword("");
  };

  return (
    <div className="group relative w-full" ref={containerRef}>
      <div className="w-full">
        <div className="relative w-full">
          {label && (
            <label className="w-full text-base text-black">
              {label}
              {optional && (
                <span className="text-sm text-gray">(optional)</span>
              )}
            </label>
          )}

          <div className="relative flex items-center w-full mt-2">
            <input
              type={"text"}
              name={name}
              value={keyword}
              onChange={handleChange}
              onFocus={() => setShowSearchResults(true)}
              placeholder={placeholder}
              autoFocus={autoFocus}
              readOnly={readOnly}
              className={`w-full text-base h-12 pl-4 py-2 rounded border-borderGray border-2 hover:border-black focus:outline-black focus:border-black active:outline-black placeholder:text-gray`}
            ></input>
            <button
              className="absolute right-2 w-8 flex items-center justify-center bg-white"
              onClick={(e) => clickSelectIcon(e)}
            >
              <img
                alt="select icon"
                src={"/select-icon.svg"}
                style={{ width: "16px", height: "16px", color: "#9FA2A8" }}
              />
            </button>
            {keyword && clearable ? (
              <button
                className="absolute right-8 w-6 flex items-center justify-center bg-white"
                onClick={(e) => clearSelect(e)}
              >
                <MdClear className="text-[#9FA2A8] w-4 h-4 hover:text-red-500" />
              </button>
            ) : (
              <></>
            )}
          </div>
          {error ? (
            <p className="w-full text-sm text-red-500 mt-2 ">{error}</p>
          ) : (
            <></>
          )}
        </div>
      </div>

      {showSearchResults ? (
        <div
          className={`absolute rounded-md  border ${
            label ? "top-[86px]" : "top-[60px]"
          } flex w-full justify-between text-black z-10 bg-popover text-popover-foreground shadow-md animate-in zoom-out-95 zoom-in-95`}
        >
          <div className="flex w-full  max-h-476 justify-start rounded bg-white shadow-md p-2 overflow-auto">
            {filterOptions.length > 0 ? (
              <ul className="w-full">
                {filterOptions.map((item, index) => (
                  <li
                    key={index}
                    className="w-full pl-2 h-8 hover:cursor-pointer hover:bg-selectHover rounded-md flex items-center justify-center"
                    onClick={() => handleSelect(item)}
                  >
                    <p className="w-full text-sm rounded">{item.name}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="text-sm text-gray">{noResultText}</p>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function GenerationSelectFiled({
  name,
  value,
  label,
  options,
  onChange,
  noResultText,
  extractKey = "id",
  placeholder = "",
  autoFocus = false,
  readOnly = false,
  error = "",
  optional = false,
}) {
  const containerRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setKeyword(value);
    if (value) {
      let filtered = options.filter((x) =>
        x.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilterOptions(filtered);
    } else {
      setFilterOptions(options);
    }
    setShowSearchResults(true);
  };

  const handleSelect = (selectedItem) => {
    onChange(selectedItem.id.toString());
    setShowSearchResults(false);
  };

  useEffect(() => {
    if (options.length) {
      setFilterOptions(options);
    }
  }, [options]);

  useEffect(() => {
    let stringValue = value ? value.toString() : "";
    if (stringValue) {
      let exist = options.filter((x) => Number(x.id) === Number(value));
      if (exist.length) {
        setKeyword("[" + exist[0].body + "] " + exist[0].name);
      } else {
        setKeyword("");
      }
    } else {
      setKeyword("");
    }
  }, [value, options]);

  useEffect(() => {
    // Function to check if click is outside the component
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
      }
    };

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clickSelectIcon = (e) => {
    e.preventDefault();
    setShowSearchResults(!showSearchResults);
  };

  return (
    <div className="group relative w-full" ref={containerRef}>
      <div className="w-full">
        <div className="relative w-full">
          <label className="w-full text-base text-black">
            {label}{" "}
            {optional && <span className="text-sm text-gray">(optional)</span>}
          </label>
          <div className="relative flex items-center w-full mt-2">
            <input
              type={"text"}
              name={name}
              value={keyword}
              onChange={handleChange}
              onFocus={() => setShowSearchResults(true)}
              placeholder={placeholder}
              autoFocus={autoFocus}
              readOnly={readOnly}
              className={`w-full text-base h-12 pl-4 py-2 rounded border-solid border-borderGray border-2 hover:border-black focus:outline-black  focus:border-black active:outline-black`}
            ></input>
            <button
              className="absolute right-0 w-10 flex items-center justify-center"
              onClick={(e) => clickSelectIcon(e)}
            >
              <img
                alt="select icon"
                src={"/select-icon.svg"}
                style={{ width: "16px", height: "16px", color: "#9FA2A8" }}
              />
            </button>
          </div>
          {error ? (
            <p className="w-full text-sm text-red-500 mt-2 ">{error}</p>
          ) : (
            <></>
          )}
        </div>
      </div>

      {showSearchResults ? (
        <div className="absolute rounded-md border top-[86px] flex w-full justify-between text-black z-10 bg-popover text-popover-foreground shadow-md animate-in zoom-out-95 zoom-in-95">
          <div className="flex w-full  max-h-476 justify-start rounded bg-white shadow-md p-2 overflow-auto">
            {filterOptions.length > 0 ? (
              <ul className="w-full">
                {filterOptions.map((item, index) => (
                  <li
                    key={index}
                    className={`pl-2 w-full ${
                      index === filterOptions.length - 1
                        ? ""
                        : "border-b border-solid border-borderGray"
                    } hover:cursor-pointer hover:bg-selectHover rounded-md`}
                    onClick={() => handleSelect(item)}
                  >
                    <div className={`w-full pr-4 py-4`}>
                      <div className="flex justify-between">
                        <div>
                          <CarBodayTag name={item.body} />

                          <span className="font-medium">{item.name}</span>
                        </div>
                        <span className="text-sm text-gray">{item.years}</span>
                      </div>

                      <div className="flex items-center justify-end ">
                        <div className="flex items-center justify-center text-gray">
                          {/* <GiCarDoor /> */}
                          <span className="text-sm">{item.doors} doors</span>
                        </div>
                        <div className="flex items-center justify-center text-gray">
                          {/* <MdAirlineSeatReclineNormal /> */}
                          <span className="text-sm">, {item.seats} seats</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="mt-4 text-sm">{noResultText}</p>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function TrimsSelectFiled({
  name,
  value,
  label,
  options,
  onChange,
  noResultText,
  extractKey = "id",
  placeholder = "",
  autoFocus = false,
  readOnly = false,
  error = "",
  optional = false,
}) {
  const containerRef = useRef(null);
  const [keyword, setKeyword] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);

  const handleChange = (e) => {
    e.preventDefault();
    let value = e.target.value;
    setKeyword(value);
    if (value) {
      let filtered = options.filter((x) =>
        x.name.toLowerCase().includes(value.toLowerCase())
      );
      setFilterOptions(filtered);
    } else {
      setFilterOptions(options);
    }
    setShowSearchResults(true);
  };

  const handleSelect = (selectedItem) => {
    onChange(selectedItem.id.toString());
    setShowSearchResults(false);
  };

  useEffect(() => {
    if (options.length) {
      setFilterOptions(options);
    }
  }, [options]);

  useEffect(() => {
    let stringValue = value ? value.toString() : "";
    if (stringValue) {
      let exist = options.filter((x) => Number(x.id) === Number(value));
      if (exist.length) {
        setKeyword(exist[0].name);
      } else {
        setKeyword("");
      }
    } else {
      setKeyword("");
    }
  }, [value, options]);

  useEffect(() => {
    // Function to check if click is outside the component
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowSearchResults(false);
      }
    };

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clearSelect = (e) => {
    e.preventDefault();
    onChange("");
    setKeyword("");
  };

  const clickSelectIcon = (e) => {
    e.preventDefault();
    setShowSearchResults(!showSearchResults);
  };

  return (
    <div className="group relative w-full" ref={containerRef}>
      <div className="w-full">
        <div className="relative w-full">
          <label className="w-full text-base text-black">
            {label}{" "}
            {optional && <span className="text-sm text-gray">(optional)</span>}
          </label>
          <div className="relative flex items-center w-full mt-2">
            <input
              type={"text"}
              name={name}
              value={keyword}
              onChange={handleChange}
              onFocus={() => setShowSearchResults(true)}
              placeholder={placeholder}
              autoFocus={autoFocus}
              readOnly={readOnly}
              className={`w-full text-base h-12 pl-4 py-2 rounded border-solid border-borderGray border-2 hover:border-black focus:outline-black  focus:border-black active:outline-black`}
            ></input>
            <button
              className="absolute right-6 w-10 flex items-center justify-center"
              onClick={(e) => clearSelect(e)}
            >
              <MdClear className="text-[#9FA2A8] w-4 h-4" />
            </button>
            <button
              className="absolute right-0 w-10 flex items-center justify-center"
              onClick={(e) => clickSelectIcon(e)}
            >
              <img
                alt="select icon"
                src={"/select-icon.svg"}
                style={{ width: "16px", height: "16px", color: "#9FA2A8" }}
              />
            </button>
          </div>
          {error ? (
            <p className="w-full text-sm text-red-500 mt-2 ">{error}</p>
          ) : (
            <></>
          )}
        </div>
      </div>

      {showSearchResults ? (
        <div className="absolute rounded-md border top-[86px] flex w-full justify-between text-black z-10 bg-popover text-popover-foreground shadow-md animate-in zoom-out-95 zoom-in-95">
          <div className="flex w-full  max-h-476 justify-start rounded bg-white shadow-md p-2 overflow-auto">
            {filterOptions.length > 0 ? (
              <ul className="w-full">
                {filterOptions.map((item, index) => (
                  <li
                    key={index}
                    className={`pl-2 w-full ${
                      index === filterOptions.length - 1
                        ? ""
                        : "border-b border-solid border-borderGray"
                    } hover:cursor-pointer hover:bg-selectHover rounded-md`}
                    onClick={() => handleSelect(item)}
                  >
                    <div className={`w-full pr-4 py-4`}>
                      <div className="w-full">
                        <div>
                          <span className="font-medium">{item.name}</span>
                        </div>
                        <div className="w-full grid grid-cols-2 lg:grid-cols-3 gap-1 mt-2">
                          {item.engine && <EngineIcon name={item.engine} />}
                          {item.fuel && <FuelTypeIcon name={item.fuel} />}
                          {item.battery && <BatteryIcon name={item.battery} />}
                          {item.trans && <TransmissionIcon name={item.trans} />}
                          {item.drive && <DriveIcon name={item.drive} />}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="mt-4">{noResultText}</p>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

function MultiUnitTextFiled({
  name,
  value,
  unitValue,
  label,
  options,
  onChange,
  onUnitChange,
  noResultText,
  extractKey = "name",
  placeholder = "",
  autoFocus = false,
  readOnly = false,
  error = "",
  optional = false,
  unitOptions = [],
}) {
  const containerRef = useRef(null);
  const [showUnitOptions, setShowUnitOptions] = useState(false);

  const handleChange = (e) => {
    e.preventDefault();
    onChange(e.target.value);
    setShowUnitOptions(false);
  };

  const handleUnitSelect = (selectedItem) => {
    onUnitChange(selectedItem);
    setShowUnitOptions(false);
  };

  useEffect(() => {
    // Function to check if click is outside the component
    const handleClickOutside = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setShowUnitOptions(false);
      }
    };
    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const clickSelectIcon = (e) => {
    e.preventDefault();
    setShowUnitOptions(!showUnitOptions);
  };

  return (
    <div className="group relative w-full" ref={containerRef}>
      <div className="w-full">
        <div className="relative w-full">
          <label className="w-full text-base text-black">
            {label}{" "}
            {optional && <span className="text-sm text-gray">(optional)</span>}
          </label>
          <div className="relative flex items-center w-full mt-2">
            <input
              type={"text"}
              name={name}
              value={value}
              onChange={handleChange}
              placeholder={placeholder}
              autoFocus={autoFocus}
              readOnly={readOnly}
              className={`w-full text-base h-12 pl-4 py-2 rounded border-solid border-borderGray border-2 hover:border-black focus:outline-black  focus:border-black active:outline-black`}
            ></input>
            <button
              className="absolute right-[2px] w-24 h-11 flex items-center justify-end bg-lightGray"
              onClick={(e) => clickSelectIcon(e)}
            >
              <span className="text-sm">{unitValue}</span>
              <div className="flex itemsc-center w-10 justify-center">
                <img
                  alt="select icon"
                  src={"/select-icon.svg"}
                  style={{ width: "16px", height: "16px", color: "#9FA2A8" }}
                />
              </div>
            </button>
          </div>
          {error ? (
            <p className="w-full text-sm text-red-500 mt-2 ">{error}</p>
          ) : (
            <></>
          )}
        </div>
      </div>

      {showUnitOptions ? (
        <div className="absolute top-18 flex w-full justify-end text-black z-10">
          <div className="flex w-24  max-h-476 justify-start rounded bg-white shadow-md p-2 overflow-auto">
            {unitOptions.length > 0 ? (
              <ul className="w-full">
                {unitOptions.map((item, index) => (
                  <li
                    key={index}
                    className="w-full px-2 h-12 hover:cursor-pointer hover:bg-lightGray flex items-center justify-center"
                    onClick={() => handleUnitSelect(item)}
                  >
                    <p className="w-full text-sm rounded">{item}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p className="mt-4 text-sm">{noResultText}</p>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export {
  FieldLabel,
  TextField,
  PasswordField,
  RatioField,
  SelectField,
  MultiSelectField,
  ReadOnlyField,
  TextAreaField,
  SearchField,
  DateInput,
  PureTextField,
  TypeSelectFiled,
  GenerationSelectFiled,
  TrimsSelectFiled,
  MultiUnitTextFiled,
};
