import React, { useState } from "react";
import { useSelector } from "react-redux";
import SingleImageUploader from "../../components/SingleImageUploader";
import { Button } from "../../components/buttons";
import { FieldLabel, TextField } from "../../components/inputs";

export default function BrandForm({ initialState, onSave }) {


  //const loading = false;
  const [formState, setFormState] = useState(initialState);

  const [formErrors, setFormErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      onSave(formState);
      // dispatch(newBrandRequest(formState));
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "Brand name is required.";
    }

    return errors;
  };

  const getUploadedImages = (file) => {
    if (file.uri) {
      setFormState({ ...formState, ["imageUri"]: file.uri });
    }
  };

  return (
    <div className="mt-8 w-full">
      <TextField
        type="text"
        label="Brand Name"
        name="name"
        autofocus={true}
        value={formState.name}
        onChange={(name) =>
          handleChange({
            target: { value: name, name: "name" },
          })
        }
        error={formErrors.name}
      />
      <FieldLabel label="Brand Photo" />
      <div className="w-80 h-80">
      <SingleImageUploader
        onUpload={getUploadedImages}
        imageError={formErrors.image}
        uri={formState.imageUri}
        folderName="watch/brand"
        compress={false}
      />

      </div>

   

      <div className="mt-8 w-full">
        <Button name="Save" action={handleSubmit} />
      </div>
    </div>
  );
}
