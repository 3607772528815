import React, { useEffect, useState, useRef } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import useDocumentTitle from "../../useDocumentTitle";
import ScreenTitle from "../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import { Button } from "@mui/material";
import { SimpleDialog } from "../../components/dialogs";
import CustomJoditEditor from "./CustomJoditEditor";
import { blogDetailsRequest } from "../../api/blogRequests";
import { useSelector } from "react-redux";
import BlogForm from "./BlogForm";

const apiUrl = process.env.REACT_APP_API_URL;

export default function BlogEdit() {
  useDocumentTitle("Blog Edit");
  const { id:blogId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [blogContent, setBlogContent] = useState();

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });


  useEffect(() => {
    const getDocDetails = async () => {
      
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await blogDetailsRequest(blogId, config);

        if (responseData.data.success) {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
          let details = responseData.data.data.article;

          setBlogContent({
            title: details.title,
            metaDescription: details.meta_description,
            metaTitle: details.meta_title,
            author: details.author,
            blogImage: details.main_image,
            readTime: details.read_time,
            content: details.content,
          })

        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }

    };
    getDocDetails();
  }, []);

 
  return (
    <div className={commonStyle.pageContainer}>

<div className="w-full p-8">
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/blogs">
              Blog List
            </NavLink>
            <NavLink
              underline="hover"
              color="text.primary"
              to={`/blog/edit/${blogId}`}
              aria-current="page"
            >
              Edit Blog
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title="Edit Blog" />
        {requestStatus.success ? (
          <BlogForm initialContent={blogContent} blogId={blogId} type="edit"/>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
