import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { PasswordField, TextField } from "../../components/inputs";
import { Button, LoadingButton } from "../../components/buttons";
import { ErrorMessage } from "../../components/smallComponents";
import { userLoginRequest } from "../../redux/actions/userActions";

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //////////////////////////////////////////////////////////////////////////
  const { success, userInfo, error, loading } = useSelector(
    (state) => state.userLogin
  );
  //////////////////////////////////////////////////////////////////////////

  const [formState, setFormState] = useState({
    email: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({
    email: "",
    password: "",
  });
  //////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (success) {
      navigate("/");
    }
  }, [success]);

  //////////////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevFormState) => ({
      ...prevFormState,
      [name]: value,
    }));
  };

  //////////////////////////////////////////////////////////////////////////

  const handleSubmit = () => {
    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      dispatch(userLoginRequest(formState));
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (!values.email) {
      errors.email = "Email is required.";
    } else if (!regexEmail.test(values.email)) {
      errors.email = "Email is invalid.";
    }

    if (!values.password) {
      errors.password = "Password is required.";
    }

    return errors;
  };

  //////////////////////////////////////////////////////////////////////////
  return (
    <div className="w-full flex items-center justify-center">
   
      <div className="w-1/2 h-screen flex flex-col items-center justify-center">
        <div className="w-full lg:max-w-476 mb-20 flex items-center justify-center">
          <img
            alt={"watch space login"}
            src={"/free-trade-logo-blue.svg"}
            className="w-full h-full object-cover"
            style={{
              maxWidth: "60%",
              maxHeight: "100%",
            }}
          />
        </div>
    

        <div className="w-full lg:max-w-476">
        <div className="w-full mb-20">
          <h1 className="">Log in</h1>
        </div>
          {error ? <ErrorMessage mess={error} /> : <></>}
          <div>
            <TextField
              type="text"
              label="Email"
              name="email"
              autofocus={true}
              value={formState.email}
              onChange={(email) =>
                handleChange({
                  target: { value: email, name: "email" },
                })
              }
              error={formErrors.email}
              readOnly={loading ? true : false}
            />

            <PasswordField
              label="Password"
              name="password"
              autofocus={false}
              showHints={false}
              value={formState.password}
              onChange={(password) =>
                handleChange({
                  target: { value: password, name: "password" },
                })
              }
              error={formErrors.password}
              readOnly={loading ? true : false}
            />
            <div className="mt-8">
              {loading ? (
                <LoadingButton name="Logging in..." />
              ) : (
                <Button name="Log in" action={handleSubmit} />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
