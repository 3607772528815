import Breadcrumbs from "@mui/material/Breadcrumbs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import ScreenTitle from "../../components/ScreenTitle";
import { SimpleDialog } from "../../components/dialogs";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import {
  editWatchRequest,
  watchDetailsRequest
} from "../../redux/actions/watchActions";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import WatchDetailsForm from "./WatchDetailsForm";

const apiUrl = process.env.REACT_APP_API_URL;

export default function EditWatch() {
  useDocumentTitle("Edit Watch");
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  //const loading = false;
  const { loading, success, error } = useSelector((state) => state.editWatch);

  const {
    loading: detailsLoading,
    success: detailsSuccess,
    watchDetails,
    error: detailsError,
  } = useSelector((state) => state.watchDetails);


  useEffect(() => {
    dispatch(watchDetailsRequest(id));
  }, [dispatch]);


  const saveNewWatch = (watchValues) => {
    let submittedValues = {
      brand_id: watchValues.brandId,
      model_id: watchValues.modelId,
      reference_number: watchValues.referenceNumber,
      watch_type_id: watchValues.watchTypeId,

      movement_id: watchValues.movementId,
      case_diameter_w: watchValues.caseDiameterWidth,
      case_diameter_h: watchValues.caseDiameterHeight,
      gender_id: watchValues.genderId,
      watch_image_uri: watchValues.imageUri,

      cali_movement: watchValues.caliMovement,
      base_caliber: watchValues.baseCaliber,
      power_reserve: watchValues.powerReserve,
      jewel_numbers: watchValues.jewelNumbers,
      frequency: watchValues.frequency,
      
      case_material_id: watchValues.caseMaterialId,
      bezel_material_id: watchValues.bezelMaterialId,
      thickness: watchValues.thickness,
      glass_id: watchValues.glassId,
      water_resistance_id: watchValues.waterResistanceId,

      dial_color_id: watchValues.dialColorId,
      dial_numerals_id: watchValues.dialNumeralsId,

      bracelet_material_id: watchValues.braceletMaterialId,
      bracelet_color_id: watchValues.braceletColorId,
      clasp_id: watchValues.claspId,
      clasp_material_id: watchValues.claspMaterialId,

      other_attribute_ids: watchValues.otherAttributeIds,

      watch_functions_ids: watchValues.watchFunctionsIds,
    };

    setDialog(true);
    dispatch(editWatchRequest(submittedValues, id))
  }
  const [dialog, setDialog] = useState(false);

  const closeDialogWithSuccess = () => {
    setDialog(false);
    navigate("/watches", { replace: true, from: location });
  };

  return (
    <div className={commonStyle.pageContainer}>
      <SimpleDialog
        title="Edit Watch"
        successMessage="Watch details updated successfully."
        isDialogOpen={dialog}
        closeDialog={() => setDialog(false)}
        loading={loading}
        loadingMessage="Processing request..."
        success={success}
        error={error}
        confirmAction={closeDialogWithSuccess}
      />

      <div className="w-full p-8">
        <Breadcrumbs aria-label="breadcrumb">
          <NavLink underline="hover" color="inherit" to="/">
            Home
          </NavLink>
          <NavLink underline="hover" color="inherit" to="/watches">
            Watches
          </NavLink>
          <NavLink
            underline="hover"
            color="text.primary"
            to={`/watch/edit/${id}`}
            aria-current="page"
          >
            Edit Watch
          </NavLink>
        </Breadcrumbs>
        <ScreenTitle title="Edit Watch" />

        <div>
          {detailsLoading ? (
            <Loader mess="Requesting watches.." />
          ) : detailsSuccess ? (
            <WatchDetailsForm
              type="edit"
              onSave={saveNewWatch}
              watchDetailsInfo={watchDetails}
              watchId={id}
            />
          ) : detailsError ? (
            <ErrorMessage mess={detailsError} />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
