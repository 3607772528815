
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const sendMessageRequest = async (values, config) => {

  try {
    const response = await axios.post(SERVER_URL + `/admin/message/create`, values, config);
    return response;
  } catch (error) {
    throw error;
  }

};

export const checkDialogExistRequest = async (productId, otherUserId, config) => {
  try {
    const response = await axios.get(
      SERVER_URL + `/admin/message/dialog-check?productId=${productId}&otherUserId=${otherUserId}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};

