import React, { useState } from "react";
import { GoCheck } from "react-icons/go";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { sendMessageRequest } from "../api/messageRequests";
import { orderActionRequest } from "../api/orderRequests";
import { K_COURIER_COMPANYS } from "../constantData/K_COURIER_COMPANYS";
import commonStyle from "../style/commonStyle.module.css";
import FilesUploader from "./FilesUploader";
import {
  Button,
  CloseButton,
  LinkButton,
  LoadingButton,
  SubmitButton,
} from "./buttons";
import { SelectField, TextAreaField, TextField } from "./inputs";
import { ErrorMessage, Loader } from "./smallComponents";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

function SimpleDialog({
  title,
  successMessage,
  isDialogOpen,
  closeDialog,
  loading,
  success,
  error,
  loadingMessage,
  btnValue,
  confirmAction,
}) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={success ? confirmAction : closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className={commonStyle.dialogTitle}>
            <h3>{title}</h3>
          </div>
          {loading ? (
            <Loader mess={loadingMessage} />
          ) : success ? (
            <>
              <p className={commonStyle.dialogSuccessMessage}>
                {successMessage}
              </p>
              <div
                className={commonStyle.confirmBtnCon}
                onClick={confirmAction}
              >
                <div className={commonStyle.confirmBtn}>OK</div>
              </div>
            </>
          ) : (
            <div>
              <p style={{ color: "red" }}>{error}</p>
              <div className={commonStyle.confirmBtnCon} onClick={closeDialog}>
                <div className={commonStyle.confirmBtn}>OK</div>
              </div>
            </div>
          )}
        </div>
      </ReactModal>
    </div>
  );
}

function OrderActionDialog({
  modalOpen,
  actionName,
  modalTitle,
  modalText,
  successActionText,
  modalButtonText,
  orderId,
  onClose,
  userType,
  successActionDone,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const modalAction = async () => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderId,
        userType,
        actionName,
        "",
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if(reqStatus.success){
      successActionDone();
    }
    onClose();
  }


  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="my-6">{successActionText}</p>
                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <p className="my-6">{modalText}</p>
                  {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                  <div className="mt-8">
                    {reqStatus.loading ? (
                      <LoadingButton name="Proceeding..." />
                    ) : (
                      <Button name={modalButtonText} action={modalAction} />
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function OrderActionWithFilesDialog({
  modalOpen,
  modalTitle,
  successActionText,
  orderId,
  userType,
  actionName,
  onClose,
  modalButtonText,
  successActionDone
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({notes:"", files:[]});
  const [formError, setFormError] = useState("");

  const getUploadedImages = (uploadedFiles) => {
    setFormState({...formState, ["files"]:uploadedFiles});
  };
  //////////////////////////////////////////////////////////////////////////

  const handleChange = (value) => {
    setFormState({...formState, ["notes"]:value});
  }
  const handleSubmit = (e) => {
    e.preventDefault();

    let fileUris = [];

    if (formState.files.length) {
      formState.files.forEach((elem) => {
        if (elem.isLoading) {
          setFormError("File still uploading, please wait a moment.");
        }
        if (elem.uri) {
          fileUris.push({fileName: elem.fileName, uri: elem.uri});
        }
      });

      if (fileUris.length) {
        let actionInfo = { files: fileUris, notes: formState.notes };
        let stringValues = JSON.stringify(actionInfo);
        proceedAction(stringValues);
      }
    } else {
      setFormError("Please upload a file");
    }
  };

  const proceedAction = async (values) => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderId,
        userType,
        actionName,
        values,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };


  const closeModal = () => {
    if(reqStatus.success){
      successActionDone();
    }
    onClose();
  }


  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="mt-6 text-base font-semibold mb-6">
                    {successActionText}
                  </p>

                  <Button name="OK" action={closeModal} />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>{modalTitle}</h2>
                  {formError && (
                    <div className="my-4">
                      <ErrorMessage mess={formError} />{" "}
                    </div>
                  )}

                  <form
                    className="w-full mt-10 lg:mt-8"
                    onSubmit={handleSubmit}
                  >
                    <FilesUploader
                      initialFiles={[]}
                      onUpload={getUploadedImages}
                    />
                      <TextAreaField
                      type="text"
                      label="Notes"
                      name="notes"
                      value={formState.notes}
                      rows={2}
                      placeholder="Your notes..."
                      onChange={(notes) => handleChange(notes)}
                    />

                    <div className="w-fit mt-6">
                      {reqStatus.error && (
                        <ErrorMessage mess={reqStatus.error} />
                      )}
                      {reqStatus.loading ? (
                        <LoadingButton name={"Proceeding..."} />
                      ) : (
                        <SubmitButton name={modalButtonText} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function SendMessageDialog({
  modalOpen,
  recipientId,
  productId,
  onClose,
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [newDialogId, setNewDialogId] = useState("");
  const [message, setMessage] = useState("");

  //////////////////////////////////////////////////////////////////////////

  const sendMessageAction = (e) => {
    e.preventDefault();

    let submitedValues = {
      recipientId: recipientId,
      productId: productId,
      message: message,
    };
    sendMessage(submitedValues);
  };

  const sendMessage = async (values) => {
    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await sendMessageRequest(values, config);

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
        setNewDialogId(responseData.data.data);
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    onClose();
  }



  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>Send Message</h2>

                  <div className="w-full flex items-center justify-center">
                    <div className="w-20 h-20 rounded-full border border-solid border-brandYellow flex items-center justify-center mt-4">
                      <GoCheck className="w-10 h-10 text-brandYellow" />
                    </div>
                  </div>
                  <p className="mt-6 text-base font-semibold mb-6">
                    Message sent successfully.
                  </p>

                  <LinkButton
                    path={`/messages/details/${newDialogId}`}
                    name="View My Message"
                  />
                </div>
              </div>
            ) : (
              <div>
                <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                  <h2>Send Message</h2>

                  <form
                    className="w-full mt-10 lg:mt-8"
                    onSubmit={sendMessageAction}
                  >
                    <TextAreaField
                      type="text"
                      label="Message"
                      name="message"
                      value={message}
                      rows={6}
                      placeholder="Your message..."
                      onChange={(message) => setMessage(message)}
                    />
                    <div className="w-fit mt-6">
                      {reqStatus.error && (
                        <ErrorMessage mess={reqStatus.error} />
                      )}
                      {reqStatus.loading ? (
                        <LoadingButton name="Sending Message" />
                      ) : (
                        <SubmitButton name={"Send Message"} />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}


function ShipOrderDialog({
  modalOpen,
  modalTitle,
  successActionText,
  orderId,
  userType,
  actionName,
  onClose,
  modalText,
  successActionDone,
  modalButtonText
}) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo ? userInfo.data.token : ""}`,
    },
  };

  const [reqStatus, setReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [formState, setFormState] = useState({
    courier: "",
    trackingNumber: "",
    courierName: "",
  });
  const [formErrors, setFormErrors] = useState({});

  const handleChange = (e) => {
    const { value, name } = e.target;
    setFormState({ ...formState, [name]: value });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.courier) {
      errors.courier = "Courier is required.";
    }

    if (!values.trackingNumber) {
      errors.trackingNumber = "Tracking number is required.";
    }

    if (values.courier && values.courier.label === "Other") {
      if (!values.courierName) {
        errors.courierName = "Courier name is required.";
      }
    }

    return errors;
  };

  const modalAction = async (e) => {
    e.preventDefault();

    const hasErrors = validate(formState);

    if (Object.keys(hasErrors).length === 0) {
      setFormErrors({});
      updateShippingDetails();
    } else {
      setFormErrors(hasErrors);
    }
  };

  const updateShippingDetails = async () => {
    let actionInfo = JSON.stringify(formState);

    try {
      setReqStatus({ ...reqStatus, loading: true });

      const responseData = await orderActionRequest(
        orderId,
        userType,
        actionName,
        actionInfo,
        config
      );

      if (responseData.data.success) {
        setReqStatus({ ...reqStatus, loading: false, success: true });
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      setReqStatus({ ...reqStatus, loading: false, error: error });
    }
  };

  const closeModal = () => {
    if(reqStatus.success){
      successActionDone();
    }
    onClose();
  }




  return (
    <div>
      <ReactModal
        isOpen={modalOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
          <div className="w-full">
            <div className="flex justify-end">
              <CloseButton action={closeModal} />
            </div>
            {reqStatus.success ? (
            <div>
              <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
                <h2>{modalTitle}</h2>
                <div className="w-full flex items-center justify-center">
                  <div className="w-20 h-20 rounded-full border border-brandYellow flex items-center justify-center mt-4">
                    <GoCheck className="w-10 h-10 text-brandYellow" />
                  </div>
                </div>
                <p className="my-6">{successActionText}</p>
                <Button name="OK" action={closeModal} />
              </div>
            </div>
          ) : (
            <div>
              <div className="w-full mt-10 lg:mt-8 flex items-center flex-col">
              <h2>{modalTitle}</h2>
                <form onSubmit={modalAction} className="mt-8 w-full">
                  <p>{modalText}</p>
                  <SelectField
                    label="Courier"
                    placeHolder=""
                    selectOptions={K_COURIER_COMPANYS}
                    value={formState.courier}
                    onChange={(courier) =>
                      handleChange({
                        target: {
                          value: courier,
                          name: "courier",
                        },
                      })
                    }
                    error={formErrors.courier}
                  />
                  {formState.courier && formState.courier.label === "Other" && (
                    <TextField
                      type="text"
                      label="Courier Company Name"
                      name="courierName"
                      value={formState.courierName}
                      onChange={(courierName) =>
                        handleChange({
                          target: {
                            value: courierName,
                            name: "courierName",
                          },
                        })
                      }
                      error={formErrors.courierName}
                    />
                  )}
                  <TextField
                    type="text"
                    label="Tracking Number"
                    name="tracking number"
                    value={formState.trackingNumber}
                    onChange={(trackingNumber) =>
                      handleChange({
                        target: {
                          value: trackingNumber,
                          name: "trackingNumber",
                        },
                      })
                    }
                    error={formErrors.trackingNumber}
                  />

                  {reqStatus.error && <ErrorMessage mess={reqStatus.error} />}
                  <div className="mt-8">
                    {reqStatus.loading ? (
                      <LoadingButton name="Proceeding..." />
                    ) : (
                      <SubmitButton name={modalButtonText} />
                    )}
                  </div>
                </form>
              </div>
            </div>
          )}
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

function ImageViewDialog({
  imageUri,
  isDialogOpen,
  closeDialog

}) {
  return (
    <div>
      <ReactModal
        isOpen={isDialogOpen}
        appElement={document.getElementById("root")}
        className={commonStyle.inputModal}
        overlayClassName={commonStyle.overlay}
        shouldFocusAfterRender={false}
        onRequestClose={closeDialog}
        shouldCloseOnOverlayClick={true}
      >
        <div className={commonStyle.dialogContentContainer}>
        <div className="flex justify-end">
              <CloseButton action={closeDialog} />
            </div>

          <div className={commonStyle.dialogTitle}>
          <div className="w-full mt-8">
                      <img
                        alt={"image preview" + imageUri}
                        src={imageUri ? S3_DOMAIN + imageUri: "/default-car.svg"
                        }
                        className="w-full h-full object-contain"
                        style={{
                          maxWidth: "100%",
                          maxHeight: "100%",
                        }}
                      />
                    </div>
          </div>
        </div>
      </ReactModal>
    </div>
  );
}

export {
  OrderActionDialog, OrderActionWithFilesDialog, SendMessageDialog, ShipOrderDialog, SimpleDialog, ImageViewDialog
};
