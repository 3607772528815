// get package list
import axios from "axios";


const SERVER_URL = process.env.REACT_APP_SERVER_URL;



export const orderActionRequest = async (orderId, userType, actionName, actionInfo, config) => {

  try {
    const response = await axios.get(
      SERVER_URL + `/admin/order/action/${orderId}?userType=${userType}&actionName=${actionName}&actionInfo=${actionInfo}`,
      config
    );
    return response;
  } catch (error) {
    throw error;
  }
};









