
import { DEALER_LIST_FAIL, DEALER_LIST_REQUEST, DEALER_LIST_SUCCESS } from "../constants/productConstants";

export const dealerListReducer = (state = { dealers: [] }, action) => {
    switch (action.type) {
      case DEALER_LIST_REQUEST:
        return { loading: true, success: false };
      case DEALER_LIST_SUCCESS:
        return { loading: false, dealers: action.payload, success: true };
      case DEALER_LIST_FAIL:
        return { loading: false, error: action.payload };
      default:
        return state;
    }
};
