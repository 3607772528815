import React from "react";
import commonStyle from "../../style/commonStyle.module.css";
// import { SimpleBlueButtonLink } from "../../components/editComponents";

export default function NotFound() {
  return (
    <div className={commonStyle.container}>
        <div className={commonStyle.brandLogoCon}>
          <div className={commonStyle.brandLogo}></div>
        </div>
      <div className={commonStyle.nopermissonWrapper}>
        <div className={commonStyle.nopermissonCon}>
       
      <h1 className={commonStyle.textLine}>404</h1>
      <h1 className={commonStyle.textLine}>Page Not Found</h1>
      <p className={commonStyle.textLine}>Oops! The page you are looking for does not exist.</p>
      {/* <SimpleBlueButtonLink name="Go to Dashboard" path="/" /> */}

        </div>
     
      </div>
    </div>
  );
}
