import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import useDocumentTitle from "../../useDocumentTitle";
import ScreenTitle from "../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import { ErrorMessage, Loader } from "../../components/smallComponents";
import Pagination from "../../components/Pagination";
import { LinkButton } from "../../components/buttons";
import { modelListRequest } from "../../redux/actions/watchActions";

export default function ModelList() {
  useDocumentTitle("Models");
  const dispatch = useDispatch();

  const { loading, models, success, error } = useSelector(
    (state) => state.modelList
  );

  const pageSize = 20;
  const [pageNum, setPageNum] = useState(0);

  const onPageChangeRequest = (page) => {
    setPageNum(page.selected);
  };

  useEffect(() => {
    dispatch(modelListRequest(pageNum, pageSize));
  }, [dispatch, pageNum]);

  return (
    <div className={commonStyle.pageContainer}>
      <div className={commonStyle.pageCardContentContainer}>
        <div className="w-full p-8">
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink underline="hover" color="inherit" to="/watch/models">
              Models
            </NavLink>
          </Breadcrumbs>

          <ScreenTitle title="Watch Models" />

          {/* hold the product list table */}
          <div className="w-1/2">
            <div className="w-full flex justify-end">
              <LinkButton
                path="/watch/model/new"
                name="Add New Model"
                width="fit"
              />
            </div>

            {loading ? (
              <Loader mess="Requesting..." />
            ) : success ? (
              <div>
                <table className={commonStyle.pageTable}>
                  <thead>
                    <tr>
                      <th>Brand</th>
                      <th>Model</th>
                    </tr>
                  </thead>
                  <tbody>
                    {success && models.models.length > 0 ? (
                      models.models.map((item, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{item.brandName}</td>
                            <td>{item.modelName}</td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td>
                          <p>There are no models available.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>

                {models.models.length > 0 ? (
                  <Pagination
                    totalPageNum={models.totalPages}
                    forcePage={pageNum}
                    onPageChange={onPageChangeRequest}
                  />
                ) : (
                  <></>
                )}
              </div>
            ) : error ? (
              <ErrorMessage mess={error} />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
