import React, { useState, useRef, useMemo } from "react";
import JoditEditor from "jodit-react";
import commonStyle from "../../style/commonStyle.module.css";
import axios from "axios";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;
const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function CustomJoditEditor({
  initialContent,
  onChange,
}) {
  const editor = useRef(null);
  const [content, setContent] = useState(initialContent);


  const config = {
    readonly: false, // all options from https://xdsoft.net/jodit/doc/
    enableDragAndDropFileToEditor: true,
    uploader: {
      url: `${SERVER_URL}/admin/image/upload`,
      isSuccess: function (resp) {
        return resp;
      },
      buildData: function (data) {
        const formData = new FormData();
        formData.append("image", data.getAll("files[0]")[0]);
        formData.append("folderName", "blog");
        return formData;
      },
      defaultHandlerSuccess: function (data) {
        this.selection.insertImage(data.image);
      },
      process: function (resp) {
        return { image: S3_DOMAIN + resp.data.url };
      },
      error: function (e) {
        this.e.fire("errorMessage", [e.getMessage(), "error", 4000]);
      },
    },
  };
  
  const handleContentChange = (newContent) => {

    setContent(newContent);
    onChange(newContent);

  }
  return (
    <div className={commonStyle.blogContentCon}>
      <JoditEditor
        ref={editor}
        value={content}
        config={config}
        tabIndex={1} // tabIndex of textarea
        onBlur={handleContentChange} // preferred to use only this option to update the content for performance reasons
      />
    </div>
  );
}
