import React, { useEffect, useState } from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import commonStyle from "../../style/commonStyle.module.css";
import { useParams } from "react-router-dom";
import axios from "axios";
import useDocumentTitle from "../../useDocumentTitle";
import ScreenTitle from "../../components/ScreenTitle";
import { NavLink } from "react-router-dom";
import parse from "html-react-parser";
import { Button } from "@mui/material";
import { blogDetailsRequest } from "../../api/blogRequests";
import { useSelector } from "react-redux";
import { LinkButton } from "../../components/buttons";

const apiUrl = process.env.REACT_APP_API_URL;

export default function BlogPreview() {
  useDocumentTitle("Blog Preview");
  const { id:blogId } = useParams();

  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
      Authorization: `Bearer ${userInfo.data.token}`,
    },
  };

  const [requestStatus, setRequestStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [docDetails, setDocDetails] = useState({});

  useEffect(() => {
    const getDocDetails = async () => {
      
      try {
        setRequestStatus((preState) => ({
          ...preState,
          loading: true,
        }));

        const responseData = await blogDetailsRequest(blogId, config);

        if (responseData.data.success) {
          setRequestStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
            error: "",
          }));
          setDocDetails(responseData.data.data.article);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setRequestStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }

    };
    getDocDetails();
  }, []);

  return (
    <div className={commonStyle.pageContainer}>
      <div className="w-full p-8">
       
        <div className={commonStyle.breadcrumbsContainer}>
          <Breadcrumbs aria-label="breadcrumb">
            <NavLink underline="hover" color="inherit" to="/">
              Home
            </NavLink>
            <NavLink
              underline="hover"
              color="inherit"
              to="/blogs"
            >
              Blogs
            </NavLink>
            <NavLink
              underline="hover"
              color="text.primary"
              to={`/blog/preview/${blogId}`}
              aria-current="page"
            >
              Preview Blog
            </NavLink>
          </Breadcrumbs>
        </div>
        <ScreenTitle title="Doc Details" />

        {requestStatus.success ? (
          <div className="w-full bg-lightGray p-4 mt-8">
            <h1>{docDetails.title}</h1>
         
         
            <div className={commonStyle.blogContentCon}>
              {parse(docDetails.content)}
            </div>

            <div className="mt-4 w-fit">   <LinkButton name="Edit" path={`/blog/edit/${blogId}`}/></div>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
