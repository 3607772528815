import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { uploadImage } from "../api/fileUpload";
import { CloseButton } from "./buttons";
import { ErrorMessage, Loader } from "./smallComponents";

const S3_DOMAIN = process.env.REACT_APP_S3_DOMAIN;

export default function SingleImageUploader({ onUpload, imageError, uri, hintText="", folderName="", compress = false }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      Authorization: `Bearer ${userInfo.data.token}`,
      "Content-Type": "multipart/form-data",
    },
  };

  // drag state
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFile, setUploadedFile] = useState({
    fileName: "",
    uri: uri,
    error: "",
  });
  const [fileError, setFileError] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);

  const dragOverHandler = (ev) => {
    ev.preventDefault();
    setDragActive(true);
  };

  // triggers when file is dropped
  const dropHandler = (ev) => {
    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();
    setFileError("");

    let allowedFiles = [];

    if (ev.dataTransfer && ev.dataTransfer.items) {
      const files = [...ev.dataTransfer.items]
        .filter((item) => item.kind === "file")
        .map((item) => item.getAsFile());

      allowedFiles = files.filter((file) => file.type.startsWith("image/"));
    } else if (ev.target.files) {
      const files = [...ev.target.files];
      allowedFiles = files.filter((file) => file.type.startsWith("image/"));
    }

    if (allowedFiles.length === 0) {
      setFileError("Only image files accepted.");
      return;
    }

    setDragActive(false);
    uploadFiles(allowedFiles);
  };

  const uploadFiles = async (files) => {
    let file = files[0];

    let fileName = file.name;
    let uri = "";
    let uploadError = "";
    const formData = new FormData();
    formData.append("image", file);
    formData.append("folderName", folderName);
    formData.append("compress", compress);

    try {
      setUploadLoading(true);
      const responseData = await uploadImage(formData, config);
      if (responseData.data.success) {
        uri = responseData.data.data.url;
      } else {
        throw responseData.data.message;
      }
    } catch (error) {
      uploadError = error.message;
    }

    let newFile = {
      fileName: fileName,
      uri: uri,
      error: uploadError,
    };

    setUploadedFile(newFile);
    setUploadLoading(false);
    onUpload(newFile);
  };

  useEffect(()=>{

    if(uri){
      setUploadedFile({
        fileName: "",
        uri: uri,
        error: "",
      });
    }

  },[uri])

  return (
    <div className="w-full flex items-center justify-center flex-col">
      {imageError ? <ErrorMessage mess={imageError} /> : <></>}
      {fileError ? <ErrorMessage mess={fileError} /> : <></>}

      <div className="w-full h-80 flex items-center mt-2 border rounded border-borderGray justify-center bg-lightGray ">
        {uploadLoading ? (
          <div className="w-full h-full flex items-center justify-center">
            <Loader />
          </div>
        ) : uploadedFile && uploadedFile.uri ? (
          <div className="relative w-full h-full items-center justify-center">
            <img
              alt={uploadedFile.fileName}
              src={S3_DOMAIN + uploadedFile.uri}
              className="w-full h-full object-contain"
              style={{ maxWidth: '100%', maxHeight: '100%' }}
            />

            <div className="absolute w-5 h-5 rounded  top-0 right-0 flex items-center justify-center">
              <CloseButton
                action={() =>
                  setUploadedFile({
                    fileName: "",
                    uri: "",
                    error: "",
                  })
                }
              />
            </div>
          </div>
        ) : (
          <div
            className="w-full h-full text-center relative border-blue-500 border-2 bg-lightGray"
            onDrop={dropHandler}
            onDragOver={dragOverHandler}
          >
            <input
              type="file"
              id="watch-photo-upload"
              className="hidden"
              accept="image/*"
              multiple
              onChange={dropHandler}
            />
            <label
              id="label-watch-photo-upload"
              htmlFor="watch-photo-upload"
              className={`flex w-full items-center justify-center h-full ${
                dragActive ? "border-black" : "border-borderGray"
              } hover:cursor-pointer`}
            >
              <div className="w-full flex flex-row lg:flex-col items-center py-6 justify-center">
                <div className="relative w-60 h-60 flex items-center justify-center">
                  <img
                    alt={uploadedFile.fileName}
                    src={"/default-car.svg"}
                    className="w-1/2 object-contain"
                  />
                </div>
                <span className="text-blue-500 lg:mt-6"> Browse images </span>
              </div>
            </label>
          </div>
        )}
      </div>
      {uploadedFile.error && (
        <p className="text-red-500 mt-2">{uploadedFile.error}</p>
      )}
      {
        hintText &&<p className="text-center mt-2">{hintText}</p>
      }
      
    </div>
  );
}
