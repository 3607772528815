import React, { useEffect, useState } from "react";
import {
  getVehicleGenerationsRequest,
  getVehicleMakeOptionsRequest,
  getVehicleModelOptionsRequest,
  getVehicleTrimsRequest,
} from "../../api/productsRequests";
import { MdKeyboardArrowDown } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import AddressCollector from "../../components/AddressCollector";
import { BackButton, Button, LoadingButton } from "../../components/buttons";
import {
  FieldLabel,
  RatioField,
  ReadOnlyField,
  SelectField,
  TextAreaField,
  TextField,
  TypeSelectFiled,
  GenerationSelectFiled,
  TrimsSelectFiled,
  MultiUnitTextFiled,
} from "../../components/inputs";
import {
  ErrorMessage,
  FormattedText,
  H1,
  H2,
} from "../../components/smallComponents";
import { userDetailsRequest } from "../../redux/actions/userActions";
import ImagesUploader from "./ImagesUploader";
import ListingSteps from "./ListingSteps";
import VehiclePreviewImage from "./VehiclePreviewImage";
import { getYears } from "../../constantData/K_YEARS";
import { K_REGIONS } from "../../constantData/K_REGIONS";
import { K_CITIES } from "../../constantData/K_CITIES";

export default function VehicleDetailsForm({
  listingId,
  type,
  onSave,
  submitLoading,
  submitError,
  initialDetails,
}) {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const config = {
    headers: {
      "Content-type": "application/json",
    },
  };

  const [makeReqStatus, setMakeReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [modelReqStatus, setModelReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [generationReqStatus, setGenerationReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });
  const [trimReqStatus, setTrimReqStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const [formState, setFormState] = useState(initialDetails);
  const [makeOptions, setMakeOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [generationOptions, setGenerationOptions] = useState([]);
  const [specOptions, setSpecOptions] = useState({});
  const [trimsOptions, setTrimsOptions] = useState([]);

  const [formErrors, setFormErrors] = useState({});

  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  useEffect(() => {
    const getMakeInfo = async () => {
      try {
        setMakeReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getVehicleMakeOptionsRequest(config);
        if (responseData.data.success) {
          setMakeReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));

          let makeOptions = responseData.data.data;

          setMakeOptions(makeOptions);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setMakeReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    getMakeInfo();
  }, []);

  useEffect(() => {
    const getModelInfo = async () => {
      try {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getVehicleModelOptionsRequest(
          formState.makeId,
          formState.year,
          config
        );
        if (responseData.data.success) {
          setModelReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          let modelOptions = responseData.data.data;
          let orgModelId = formState.modelId;
          // if current model in not exist in the new option list, remove it. otherwise keep it.
          if (
            !modelOptions.filter((x) => Number(x.id) === Number(orgModelId))
              .length
          ) {
            setFormState({ ...formState, modelId: "" });
          }
          setModelOptions(modelOptions);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setModelReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (formState.makeId && formState.year) {
      getModelInfo();
    } else {
      setModelOptions([]);
    }
    // reset generation and trim options
    setGenerationOptions([]);
    setTrimsOptions([]);
  }, [formState.makeId, formState.year]);

  useEffect(() => {
    const getGenerations = async () => {
      try {
        setGenerationReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getVehicleGenerationsRequest(
          formState.makeId,
          formState.year,
          formState.modelId,
          config
        );
        if (responseData.data.success) {
          setGenerationReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          let generationOptions = responseData.data.data;
          setGenerationOptions(generationOptions);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setGenerationReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (formState.makeId && formState.year && formState.modelId) {
      getGenerations();
    } else {
      setGenerationOptions([]);
    }
    setTrimsOptions([]);
  }, [formState.makeId, formState.year, formState.modelId]);

  useEffect(() => {
    const getTrims = async () => {
      try {
        setTrimReqStatus((preState) => ({
          ...preState,
          loading: true,
        }));
        const responseData = await getVehicleTrimsRequest(
          formState.generationId,
          formState.year,
          config
        );
        if (responseData.data.success) {
          setTrimReqStatus((preState) => ({
            ...preState,
            loading: false,
            success: true,
          }));
          let trimsOptions = responseData.data.data;
          setSpecOptions(trimsOptions);
          setTrimsOptions(trimsOptions.trims);
        } else {
          throw responseData.data.message;
        }
      } catch (error) {
        setTrimReqStatus((preState) => ({
          ...preState,
          loading: false,
          error: error,
        }));
      }
    };
    if (formState.generationId && formState.year) {
      getTrims();
    } else {
      setTrimsOptions([]);
    }
  }, [formState.generationId, formState.year]);
  //////////////////////////////////////////////////////////////////
  //////////////////////////////////////////////////////////////////

  const handleChange = (e) => {
    const { name, value } = e.target;
    const type = e.type;
    if (type === "FLOAT") {
      const validated = value.match(/^(\d*\.{0,1}\d{0,2}$)/);
      if (!validated) {
        return;
      }
    }

    if (type === "INT") {
      const validated = value.match(/^(?:\d+)?$/g);
      if (!validated) {
        return;
      }
    }

    setFormState({ ...formState, [name]: value });
  };

  const handleMakeChange = (e) => {
    const { name, value } = e.target;
    let copy = { ...formState };
    if (Number(copy.makeId) !== Number(value)) {
      setFormState((currentState) => ({
        ...currentState,
        [name]: value,
        modelId: "",
        generationId: "",
        specId: "",
        transmissionId: "",
        fuelTypeId: "",
      }));
    }
  };

  const handleModelChange = (e) => {
    const { name, value } = e.target;
    let copy = { ...formState };
    if (Number(copy.modelId) !== Number(value)) {
      setFormState((currentState) => ({
        ...currentState,
        [name]: value,
        generationId: "",
        specId: "",
        transmissionId: "",
        fuelTypeId: "",
      }));
    }
  };

  const handleGenerationChange = (e) => {
    const { name, value } = e.target;
    let copy = { ...formState };
    if (Number(copy.generationId) !== Number(value)) {
      setFormState((currentState) => ({
        ...currentState,
        [name]: value,
        specId: "",
        transmissionId: "",
        fuelTypeId: "",
      }));
    }
  };

  const handleFuelTypeChange = (e) => {
    const { name, value } = e.target;
    let copy = { ...formState };
    if (Number(copy.fuelTypeId) !== Number(value)) {
      setFormState((currentState) => ({
        ...currentState,
        [name]: value,
        specId: "",
      }));
    }
  };

  //////////////////////////////////////////////////////////////////

  const handleSubmit = () => {
    const hasErrors = validate(formState);



    if (Object.keys(hasErrors).length === 0) {

      setFormErrors({});
      onSave(formState)
    } else {
      setFormErrors(hasErrors);
    }
  };

  const validate = (values) => {
    const errors = {};

    if (!values.makeId) {
      errors.make = "Make is required.";
    }
    if (!values.modelId) {
      errors.model = "Model is required.";
    }

    if (!values.year) {
      errors.year = "Year is required.";
    }

    if (!values.transmissionId) {
      errors.transmission = "Transmission is required.";
    }
    if (!values.generationId) {
      errors.generation = "Body & generation is required.";
    }

    return errors;
  };

  return (
    <div className="w-full pb-20 pt-4">
      <div className="w-full flex">
        <div className="w-full lg:w-3/5">
          {/* step 1 start */}

          <div className="w-full">
            <H1 title="Vehicle Details" />
            <div className="mt-6">
              <H2 title="Basic Information" />
            </div>

            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
              <TypeSelectFiled
                label="Make"
                options={makeReqStatus.success ? makeOptions : []}
                name={"make"}
                extractKey="id"
                value={formState.makeId}
                onChange={(makeId) =>
                  handleMakeChange({
                    type: "ID",
                    target: { value: makeId, name: "makeId" },
                  })
                }
                placeholder="type to search make"
                autoFocus={false}
                readOnly={false}
                error={formErrors.make}
                optional={false}
              />
              <TypeSelectFiled
                label="Year"
                extractKey="id"
                options={getYears()}
                name={"year"}
                value={formState.year}
                onChange={(year) =>
                  handleChange({
                    type: "text",
                    target: { value: year, name: "year" },
                  })
                }
                placeholder="type to search year"
                autoFocus={false}
                readOnly={false}
                error={formErrors.year}
                optional={false}
              />
              <TypeSelectFiled
                label="Model"
                options={modelReqStatus.success ? modelOptions : []}
                name={"model"}
                extractKey="id"
                value={formState.modelId}
                onChange={(modelId) =>
                  handleModelChange({
                    type: "ID",
                    target: { value: modelId, name: "modelId" },
                  })
                }
                noResultText="Please select a make and year firstly."
                placeholder="type to search model"
                autoFocus={false}
                readOnly={false}
                error={formErrors.model}
                optional={false}
              />
            </div>

            <div className="w-full mt-4">
              <GenerationSelectFiled
                label="Body & Generation"
                options={generationReqStatus.success ? generationOptions : []}
                name={"generation"}
                extractKey="id"
                value={formState.generationId}
                onChange={(generationId) =>
                  handleGenerationChange({
                    type: "ID",
                    target: { value: generationId, name: "generationId" },
                  })
                }
                placeholder="type to search generation"
                noResultText="Please select a make and model firstly."
                autoFocus={false}
                readOnly={false}
                error={formErrors.generation}
                optional={false}
              />
            </div>

            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
              <TypeSelectFiled
                label="Transmission"
                options={trimReqStatus.success ? specOptions.trans : []}
                name={"transmission"}
                extractKey="id"
                value={formState.transmissionId}
                onChange={(transmissionId) =>
                  handleChange({
                    type: "ID",
                    target: {
                      value: transmissionId,
                      name: "transmissionId",
                    },
                  })
                }
                placeholder="select transmission"
                autoFocus={false}
                readOnly={false}
                error={formErrors.transmission}
                optional={false}
              />
              <TypeSelectFiled
                label="Fuel Type"
                extractKey="id"
                options={trimReqStatus.success ? specOptions.fuels : []}
                name={"fuelType"}
                value={formState.fuelTypeId}
                onChange={(fuelTypeId) =>
                  handleFuelTypeChange({
                    type: "ID",
                    target: { value: fuelTypeId, name: "fuelTypeId" },
                  })
                }
                placeholder="select fuel type"
                autoFocus={false}
                readOnly={false}
                error={formErrors.fuelType}
                optional={true}
              />
            </div>

            <div className="w-full mt-4">
              <TrimsSelectFiled
                label="Engine Spec"
                options={
                  trimReqStatus.success
                    ? trimsOptions.filter((x) => {
                        // Initialize a flag to true
                        let include = true;
                        // Filter by fuel type ID if one is selected
                        if (
                          formState.fuelTypeId &&
                          x.fuelId &&
                          Number(x.fuelId) != Number(formState.fuelTypeId)
                        ) {
                          include = false;
                        }
                        // Filter by transmission ID if one is selected

                        // Return the flag indicating whether x should be included
                        return include;
                      })
                    : []
                }
                name={"spec"}
                extractKey="id"
                value={formState.specId}
                onChange={(specId) =>
                  handleChange({
                    type: "ID",
                    target: { value: specId, name: "specId" },
                  })
                }
                placeholder="type to search configuration"
                noResultText="Please select a body & generation firstly."
                autoFocus={false}
                readOnly={false}
                error={formErrors.spec}
                optional={true}
              />
            </div>
            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 mt-4">
              <TextField
                type="text"
                label="Plate Number"
                name="plate"
                autofocus={true}
                value={formState.plate}
                onChange={(plate) =>
                  handleChange({
                    type: "TEXT",
                    target: { value: plate, name: "plate" },
                  })
                }
                dollarIcon={false}
                error={formErrors.plate}
                optional={true}
              />
            </div>
          </div>

          <div className="mt-6">
            <H2 title="Price & Description" />
          </div>

          <div className="w-full grid grid-cols-1 gap-4 mt-4">
            <TextField
              type="text"
              label="Title"
              name="title"
              autofocus={true}
              optional={true}
              value={formState.title}
              onChange={(title) =>
                handleChange({
                  type: "TEXT",
                  target: { value: title, name: "title" },
                })
              }
            />

            <TextField
              type="text"
              label="Subtitle"
              name="subtitle"
              autofocus={true}
              optional={true}
              value={formState.subtitle}
              onChange={(subtitle) =>
                handleChange({
                  type: "TEXT",
                  target: { value: subtitle, name: "subtitle" },
                })
              }
            />

            <TextField
              type="text"
              label="Price"
              name="price"
              autofocus={true}
              value={formState.price}
              onChange={(price) =>
                handleChange({
                  type: "FLOAT",
                  target: { value: price, name: "price" },
                })
              }
              dollarIcon={true}
              error={formErrors.price}
            />
          </div>
          <FieldLabel label={"Description"} />
          <div className="w-full mt-4">
            <FormattedText text={formState.description} />
          </div>

          <div className="mt-8 w-full flex justify-between">
            <div className="flex-grow">
              <Button
                name={"Save Update"}
                action={handleSubmit}
                isActive={true}
              />
            </div>
          </div>
        </div>

        <div className={`hidden lg:block lg:w-2/5 lg:px-8 sticky top-80 h-fit`}>
          <VehiclePreviewImage
            title={formState.title}
            subtitle={formState.subtitle}
            price={formState.price}
            image={
              formState.images && formState.images.length
                ? formState.images[0]
                : ""
            }
          />
        </div>
      </div>
    </div>
  );
}
