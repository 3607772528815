import React, {useState, useEffect} from "react";
import commonStyle from "../../style/commonStyle.module.css";

export default function ErrorBoundary({ children }) {
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    const handleError = (error) => {
      // You can log the error to an error tracking service here
      console.error(error);
      setHasError(true);
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  if (hasError) {
    return (
      <div className={commonStyle.container}>
        <div className={commonStyle.brandLogoCon}>
          <div className={commonStyle.brandLogo}></div>
        </div>
        <div className={commonStyle.nopermissonWrapper}>
          <div className={commonStyle.nopermissonCon}>
            <h1>Sorry, something went wrong</h1>
            <p className={commonStyle.textLine}>Please try again later.</p>
            <p className={commonStyle.textLine}>If the issue continus, please contact</p>
            <a href = 'mailto:support@ruiautomation.com' className={commonStyle.textLine}>support@ruiautomation.com</a>

          </div>
        </div>
      </div>
    );
  } else {
    return children;
  }
}
